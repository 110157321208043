import { Col, Row, Tooltip } from "antd"
import { get } from "lodash"
import React from "react"
import { container } from "tsyringe"
import { ChartDataContainer } from "../../../../containers/ChartDataContainer"
import { ChartOptionsContainer } from "../../../../containers/ChartOptionsContainer"
import { ChartKey, ChartType } from "../../../../types"
import * as co from "./chartjs/options"
import ChartLegend from "./google/components/ChartLegend"
import ColorAxis from "./google/components/ColorAxis"
import GraphAxis from "./google/components/GraphAxis"
import Series from "./google/components/Series"
import * as go from "./google/options"
import OptionSelector from "./OptionSelector"
import { ColorInput, OptionItem, OptionLabel, OptionValueCol } from "./style"

export const getChartOptions = (
    chartType: ChartType, chartKey: ChartKey,
) => {
    let options: JSX.Element[] = []

    switch (chartType) {
        case ChartType.area:
            options = [
                go.title(chartKey),
                go.titlePosition(chartKey),
                go.aggregationTarget(chartKey),
                go.theme(chartKey),
                go.fontName(chartKey),
                go.fontSize(chartKey),
                go.lineWidth(chartKey),
                go.backgroundColor(chartKey),
                go.axisTitlesPosition(chartKey),
                go.orientation(chartKey),
                go.isStacked(chartKey),
                go.focusTarget(chartKey),
                go.dataOpacity(chartKey),
                <ChartLegend chartKey={chartKey} key={`${chartKey}legend`}/>,
                <Series chartKey={chartKey} includeTrendlines={false} key={`${chartKey}series`}/>,
                <GraphAxis chartKey={chartKey} axis="h" key={`${chartKey}haxis`}/>,
                <GraphAxis chartKey={chartKey} axis="v" key={`${chartKey}vaxis`}/>,
        ]
            break
        case ChartType.line:
            options = [
                    go.title(chartKey),
                    go.titlePosition(chartKey),
                    go.aggregationTarget(chartKey),
                    go.theme(chartKey),
                    go.fontName(chartKey),
                    go.fontSize(chartKey),
                    go.lineWidth(chartKey),
                    go.backgroundColor(chartKey),
                    go.axisTitlesPosition(chartKey),
                    go.orientation(chartKey),
                    go.focusTarget(chartKey),
                    go.curveType(chartKey),
                    go.dataOpacity(chartKey),
                    <ChartLegend chartKey={chartKey} key={`${chartKey}legend`}/>,
                    <Series chartKey={chartKey} includeTrendlines={true} key={`${chartKey}series`}/>,
                    <GraphAxis chartKey={chartKey} axis="h" key={`${chartKey}haxis`}/>,
                    <GraphAxis chartKey={chartKey} axis="v" key={`${chartKey}vaxis`}/>,
            ]
            break
        case ChartType.bar:
            options = [
                go.title(chartKey),
                go.titlePosition(chartKey),
                go.aggregationTarget(chartKey),
                go.theme(chartKey),
                go.fontName(chartKey),
                go.fontSize(chartKey),
                go.backgroundColor(chartKey),
                go.axisTitlesPosition(chartKey),
                go.isStacked(chartKey),
                go.focusTarget(chartKey),
                go.dataOpacity(chartKey),
                <ChartLegend chartKey={chartKey} key={`${chartKey}legend`}/>,
                <Series chartKey={chartKey} includeTrendlines={false} key={`${chartKey}series`}/>,
                <GraphAxis chartKey={chartKey} axis="h" key={`${chartKey}haxis`}/>,
                <GraphAxis chartKey={chartKey} axis="v" key={`${chartKey}vaxis`}/>,
            ]
            break
        case ChartType.bubble:
            options = [
                go.title(chartKey),
                go.titlePosition(chartKey),
                go.theme(chartKey),
                go.fontName(chartKey),
                go.fontSize(chartKey),
                go.backgroundColor(chartKey),
                go.axisTitlesPosition(chartKey),
                <ChartLegend chartKey={chartKey} key={`${chartKey}legend`}/>,
                <Series chartKey={chartKey} includeTrendlines={false} key={`${chartKey}series`}/>,
                <GraphAxis chartKey={chartKey} axis="h" key={`${chartKey}haxis`}/>,
                <GraphAxis chartKey={chartKey} axis="v" key={`${chartKey}vaxis`}/>,
                <ColorAxis chartKey={chartKey} key={`${chartKey}coloraxis`}/>,
            ]
            break
        case ChartType.candlestick:
            options = [
                go.title(chartKey),
                go.titlePosition(chartKey),
                go.aggregationTarget(chartKey),
                go.theme(chartKey),
                go.fontName(chartKey),
                go.fontSize(chartKey),
                go.backgroundColor(chartKey),
                go.axisTitlesPosition(chartKey),
                go.orientation(chartKey),
                go.focusTarget(chartKey),
                <ChartLegend chartKey={chartKey} key={`${chartKey}legend`}/>,
                <Series chartKey={chartKey} includeTrendlines={false} key={`${chartKey}series`}/>,
                <GraphAxis chartKey={chartKey} axis="h" key={`${chartKey}haxis`}/>,
                <GraphAxis chartKey={chartKey} axis="v" key={`${chartKey}vaxis`}/>,
            ]
            break
        case ChartType.column:
            options = [
                go.title(chartKey),
                go.titlePosition(chartKey),
                go.aggregationTarget(chartKey),
                go.theme(chartKey),
                go.fontName(chartKey),
                go.fontSize(chartKey),
                go.backgroundColor(chartKey),
                go.axisTitlesPosition(chartKey),
                go.isStacked(chartKey),
                go.focusTarget(chartKey),
                <ChartLegend chartKey={chartKey} key={`${chartKey}legend`}/>,
                <Series chartKey={chartKey} includeTrendlines={false} key={`${chartKey}series`}/>,
                <GraphAxis chartKey={chartKey} axis="h" key={`${chartKey}haxis`}/>,
                <GraphAxis chartKey={chartKey} axis="v" key={`${chartKey}vaxis`}/>,
            ]
            break
        case ChartType.combo:
            options = [
                go.title(chartKey),
                go.titlePosition(chartKey),
                go.aggregationTarget(chartKey),
                go.theme(chartKey),
                go.fontName(chartKey),
                go.fontSize(chartKey),
                go.lineWidth(chartKey),
                go.backgroundColor(chartKey),
                go.axisTitlesPosition(chartKey),
                go.orientation(chartKey),
                go.isStacked(chartKey),
                go.focusTarget(chartKey),
                go.curveType(chartKey),
                go.dataOpacity(chartKey),
                <ChartLegend chartKey={chartKey} key={`${chartKey}legend`}/>,
                <Series chartKey={chartKey} includeTrendlines={false} key={`${chartKey}series`}/>,
                <GraphAxis chartKey={chartKey} axis="h" key={`${chartKey}haxis`}/>,
                <GraphAxis chartKey={chartKey} axis="v" key={`${chartKey}vaxis`}/>,
            ]
            break
        case ChartType.geo:
            options = [
                go.backgroundColor(chartKey),
                go.datalessRegionColor(chartKey),
                go.defaultColor(chartKey),
                // requires api key for Geocoding service
                // https://developers.google.com/maps/documentation/javascript/get-api-key
                // displayMode(chartKey),
                go.enableRegionInteractivity(chartKey),
                go.keepAspectRatio(chartKey),
                go.markerOpacity(chartKey),
                go.region(chartKey),
                go.resolution(chartKey),
                <ColorAxis chartKey={chartKey} key={`${chartKey}coloraxis`}/>,
            ]
            break
        case ChartType.histogram:
            options = [
                go.title(chartKey),
                go.titlePosition(chartKey),
                go.theme(chartKey),
                go.fontName(chartKey),
                go.fontSize(chartKey),
                go.backgroundColor(chartKey),
                go.axisTitlesPosition(chartKey),
                go.orientation(chartKey),
                go.isStacked(chartKey),
                go.focusTarget(chartKey),
                go.dataOpacity(chartKey),
                <ChartLegend chartKey={chartKey} key={`${chartKey}legend`}/>,
                <Series chartKey={chartKey} includeTrendlines={false} key={`${chartKey}series`}/>,
                <GraphAxis chartKey={chartKey} axis="h" key={`${chartKey}haxis`}/>,
                <GraphAxis chartKey={chartKey} axis="v" key={`${chartKey}vaxis`}/>,
            ]
            break
        case ChartType.pie:
            options = [
                go.title(chartKey),
                go.fontName(chartKey),
                go.fontSize(chartKey),
                go.backgroundColor(chartKey),
                <ChartLegend chartKey={chartKey}/>,
            ]
            break
        case ChartType.scatter:
            options = [
                go.title(chartKey),
                go.titlePosition(chartKey),
                go.aggregationTarget(chartKey),
                go.theme(chartKey),
                go.fontName(chartKey),
                go.fontSize(chartKey),
                go.lineWidth(chartKey),
                go.backgroundColor(chartKey),
                go.axisTitlesPosition(chartKey),
                go.orientation(chartKey),
                go.curveType(chartKey),
                go.dataOpacity(chartKey),
                <ChartLegend chartKey={chartKey} key={`${chartKey}legend`}/>,
                <Series chartKey={chartKey} includeTrendlines={true} key={`${chartKey}series`}/>,
                <GraphAxis chartKey={chartKey} axis="h" key={`${chartKey}haxis`}/>,
                <GraphAxis chartKey={chartKey} axis="v" key={`${chartKey}vaxis`}/>,
        ]
            break
        case ChartType.stepped_area:
            options = [
                go.title(chartKey),
                go.titlePosition(chartKey),
                go.aggregationTarget(chartKey),
                go.theme(chartKey),
                go.fontName(chartKey),
                go.fontSize(chartKey),
                go.backgroundColor(chartKey),
                go.axisTitlesPosition(chartKey),
                go.isStacked(chartKey),
                go.focusTarget(chartKey),
                <ChartLegend chartKey={chartKey} key={`${chartKey}legend`}/>,
                <Series chartKey={chartKey} includeTrendlines={false} key={`${chartKey}series`}/>,
                <GraphAxis chartKey={chartKey} axis="h" key={`${chartKey}haxis`}/>,
                <GraphAxis chartKey={chartKey} axis="v" key={`${chartKey}vaxis`}/>,
            ]
            break
        case ChartType.chartjs_doughnut:
        case ChartType.chartjs_pie:
            options = [
                co.responsive(chartKey),
                co.cutoutPercentage(chartKey),
                co.circumference(chartKey),
                co.title(chartKey),
                co.legend(chartKey),
                co.pieDatasets(chartKey),
                co.tooltip(chartKey),
                co.hover(chartKey),
                co.animation(chartKey),
            ]
            break
        case ChartType.chartjs_radar:
            options = [
                co.responsive(chartKey),
                co.spanGaps(chartKey),
                co.title(chartKey),
                co.legend(chartKey),
                co.radarDatasets(chartKey),
                co.tooltip(chartKey),
                co.hover(chartKey),
                co.animation(chartKey),
            ]
            break
        case ChartType.chartjs_line:
            options = [
                co.responsive(chartKey),
                co.showLines(chartKey),
                co.spanGaps(chartKey),
                co.title(chartKey),
                co.legend(chartKey),
                co.lineDatasets(chartKey),
                co.tooltip(chartKey),
                co.hover(chartKey),
                co.animation(chartKey),
            ]
            break
        case ChartType.chartjs_bar:
        case ChartType.chartjs_bubble:
            options = [
                co.responsive(chartKey),
                co.title(chartKey),
                co.legend(chartKey),
                co.barDatasets(chartKey),
                co.tooltip(chartKey),
                co.hover(chartKey),
                co.animation(chartKey),
                // exluded these:
                // elements?: ChartElementsOptions;
                // layout?: ChartLayoutOptions;
                // scale?: RadialLinearScale;
            ]
            break
        case ChartType.chartjs_horizontal_bar:
        case ChartType.chartjs_polar:
            options = [
                co.responsive(chartKey),
                co.title(chartKey),
                co.legend(chartKey),
                co.pieDatasets(chartKey),
                co.tooltip(chartKey),
                co.hover(chartKey),
                co.animation(chartKey),
                // exluded these:
                // elements?: ChartElementsOptions;
                // layout?: ChartLayoutOptions;
                // scale?: RadialLinearScale;
            ]
            break
    case ChartType.chartjs_scatter:
        options = [
            co.responsive(chartKey),
            co.title(chartKey),
            co.legend(chartKey),
            co.lineDatasets(chartKey),
            co.tooltip(chartKey),
            co.hover(chartKey),
            co.animation(chartKey),
            // exluded these:
            // elements?: ChartElementsOptions;
            // layout?: ChartLayoutOptions;
            // scale?: RadialLinearScale;
        ]
        break
    }

    return options
}

export const buildOption = (
    chartKey: ChartKey,
    label: string,
    optionPath: string,
    chartOptionType: ChartOptionType,
    choices?: EnumChoice[] | string[],
    allowClear?: boolean,
) => {
    const Option: React.FunctionComponent = () => {
        const initialValue = getInitialOptionValue(optionPath, choices)
        return(
            <OptionItem>
                <Row style={{width: "100%"}}>
                    <Col span={12}>
                        <Tooltip title={label}>
                            <OptionLabel>{label}</OptionLabel>
                        </Tooltip>
                    </Col>
                    <OptionValueCol span={12}>
                        <OptionSelector
                            chartKey={chartKey}
                            chartOptionType={chartOptionType}
                            optionPath={optionPath}
                            initialValue={initialValue}
                            choices={choices}
                            allowClear={allowClear}
                        />
                    </OptionValueCol>
                </Row>
            </OptionItem>
        )
    }
    return <Option key={`${chartKey}${optionPath}`}/>
}

const getInitialOptionValue = (optionPath: string, choices?: EnumChoice[] | string[]) => {
    let initialValue: string | number | boolean | undefined | string[] | number[]
    const chartDataContainer = container.resolve(ChartDataContainer)
    const chartOptionsContainer = container.resolve(ChartOptionsContainer)
    const selected = chartOptionsContainer.getSelectedChart()

    if (selected) {
        const data = chartDataContainer.getData(selected)
        const initial = get(data?.options, optionPath)
        if (choices && isEnumChoice(choices)) {
            initialValue = choices.find(c => c.value === initial)?.label || initial
        } else {
            initialValue = initial
        }
    }
    return initialValue
}

export const colorInputSelector = (value: string | undefined, setValue: (c: string | undefined) => void) => {
    return(
        <React.Fragment>
            <ColorInput
                style={{ backgroundColor: value}}
                value={value}
                onChange={e => setValue(e.target.value || undefined)}
            />
        </React.Fragment>
    )
}

export const ChartPointShape = ["circle", "triangle", "square", "diamond", "star", "polygon"]

export const isEnumChoice = (cs: any[]): cs is EnumChoice[] =>
    cs.length > 0 && cs.every(c => c.value && c.label)
