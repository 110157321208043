import { min } from "lodash"
import React, { useCallback, useEffect, useState } from "react"
import Chart from "../../app/components/main/chart/Chart"
import ChartItLoading from "../../app/components/main/chart/ChartItLoading"
import { ChartDataProps } from "../../app/types"
import { getSavedChart } from "../../app/utils/s3Utils"
interface LandingSplashChartProps {
    chartKey: string,
    chartId: string,
    group: string
}

const LandingSplashChart: React.FunctionComponent<LandingSplashChartProps> = (props) => {
    const [width, setWidth] = useState(0)
    const [height, setHeight] = useState(0)
    const [showChart, setShowChart] = useState(true)
    const [chartData, setChartData] = useState<ChartDataProps>()

    const { chartId, group, chartKey} = props

    const loadChart = useCallback(
        (id: string, gp: string) => getSavedChart({id, group: gp}).then(d => {
            const c = d.chartData.find(cd => cd.key === chartKey)
            if (c !== undefined) {
                setChartData(c)
                const w = min([c.width, window.innerWidth * 0.9]) || 0
                setWidth(w)
                setHeight(c.height)
            }
        }), [])

    useEffect(() => {
        loadChart(chartId, group)
    }, [loadChart, chartId, group])

    if (!showChart || chartData?.labelColumns.length === 0) { return null }
    if (!chartData || !chartData.type) { return <ChartItLoading/> }

    return(
        <Chart
            name={chartData.name}
            group={group}
            chartId={chartId}
            chartKey={chartData.key}
            labelColumns={chartData.labelColumns}
            dataColumns={chartData.dataColumns}
            chartType={chartData.type}
            width={width}
            height={height}
            resize={(size) => {
                setWidth(size.width)
                setHeight(size.height)
            }}
            dataName={chartData.name}
            handleOnDelete={() => setShowChart(false)}
            chartOptions={chartData.options}
            readOnly={true}
            saveChart={() => new Promise(() => undefined) }
        />
    )
}

export default LandingSplashChart
