import { injectable, singleton } from "tsyringe"
import { DataColumn, DataFormatType, DataKey, DataProps, DelimiterType, DataFormat } from "../types"
import { emptyDataProps, textToColumns, updateDataFormatType } from "../utils/dataUtils"
import { BaseContainer } from "./BaseContainer"

@singleton()
@injectable()
export class DataContainer extends BaseContainer<DataProps> {
    protected prefix = "Data"
    protected emptyData = emptyDataProps

    public constructor() {
        super()
    }

    public updateDataFormatType = (
        key: DataKey, column: DataColumn, dataFormat: DataFormatType, callback?: () => void,
    ) => {
        const data = this.getData(key)
        if (data) {
            const updatedColumn = updateDataFormatType(column, dataFormat)
            const updatedColumns = data.columns.map(d => d.key === updatedColumn.key ? updatedColumn : d)
            const updated = {...data, columns: updatedColumns}
            this.updateData(updated, callback)
        }
    }

    public updateDataColumns = (
        key: DataKey, raw: string, delimiterType: DelimiterType | undefined, callback?: () => void,
    ) => {
        const data = this.getData(key)
        if (data) {
            const dataTypes: {[x: string]: DataFormat} = {}
            data.columns.forEach(c => dataTypes[c.name] = {dataFormat: c.dataFormat, dateFormat: c.dateFormat})
            const columns = textToColumns(raw, dataTypes, delimiterType)
            const updated = {...data, raw, columns}
            this.updateData(updated, callback)
        }
    }
}
