import { Collapse } from "antd"
import React from "react"
import { ChartKey } from "../../../../../../types"
import { buildOption as b } from "../../utils"

interface TrendLinesProps {
    chartKey: ChartKey
    idx: number
}

const TrendLines: React.FunctionComponent<TrendLinesProps> = (props) => {
    const { chartKey, idx } = props
    const trendlineTypes = [
        {label: "linear", value: "linear"},
        {label: "exponential", value: "exponential"},
        {label: "polynomial", value: "polynomial"},
    ]
    return(
        <Collapse bordered={false} style={{width: "100%"}}>
            <Collapse.Panel header={"Trend Lines"} key={"trendLines"}>
                {b(chartKey, "Type", `google.trendlines.${idx}.type`, "enum", trendlineTypes)}
                {b(chartKey, "Degree", `google.trendlines.${idx}.degree`, "number")}
                {b(chartKey, "Color", `google.trendlines.${idx}.color`, "color")}
                {b(chartKey, "Line Width", `google.trendlines.${idx}.lineWidth`, "number")}
                {b(chartKey, "Opacity", `google.trendlines.${idx}.opacity`, "number")}
                {b(chartKey, "Point Size", `google.trendlines.${idx}.pointSize`, "number")}
                {b(chartKey, "Points Visible", `google.trendlines.${idx}.pointsVisible`, "boolean")}
                {b(chartKey, "Label in Legend", `google.trendlines.${idx}.labelInLegend`, "string")}
                {b(chartKey, "Visible in Legend", `google.trendlines.${idx}.visibleInLegend`, "boolean")}
                {b(chartKey, "Show R2", `google.trendlines.${idx}.showR2`, "boolean")}
            </Collapse.Panel>
        </Collapse>
    )
}

export default TrendLines
