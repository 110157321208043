import { Col, List, Popover, Progress, Row } from "antd"
import TextArea from "antd/lib/input/TextArea"
import React, { useEffect, useRef, useState } from "react"
import { container } from "tsyringe"
import { Subscribe } from "unstated"
import { ChartDefaults } from "../../../config"
import { DataContainer } from "../../../containers/DataContainer"
import { MetaContainer } from "../../../containers/MetaContainer"
import { TutorialContainer } from "../../../containers/TutorialContainer"
import { FullWidthButton, SiderCollapsePanel, SiderInput } from "../../../style"
import { tutorial2 } from "../../../tutorial/tutorialSteps"
import { DelimiterType } from "../../../types"
import { inferDelimiterType } from "../../../utils/dataUtils"
import DeleteModal from "../../modals/DeleteModal"
import DataColumnValidator from "./DataColumnValidator"
import DataDelimitorSelector from "./DataDelimiterSelector"

interface DataPanelProps {
    dataKey: string
}

const DataPanel: React.FunctionComponent<DataPanelProps> = (dataProps) => {
    // https://github.com/react-component/collapse/issues/73#issuecomment-323626120
    const {
        dataKey,
        ...props
    } = dataProps

    const dataContainer = container.resolve(DataContainer)
    const metaContainer = container.resolve(MetaContainer)
    const initialData = dataContainer.getData(dataKey)

    const [delimiterType, setDelimiterType] = useState<DelimiterType>()
    const [inferredDelimiterType, setInferredDelimiterType] = useState(ChartDefaults.delimiterType)
    const [raw, setRaw] = useState(initialData?.raw || "")
    const [dataName, setDataName] = useState<string>(initialData?.name || "")
    const [notes, setNotes] = useState(initialData?.notes || "")
    const [showDeleteModal, setShowDeleteModal] = useState(false)

    const isFirstRun = useRef(true)
    const readOnly = metaContainer.state.readOnly

    useEffect(() => {
        if (isFirstRun.current) {
            // data already loaded!
            isFirstRun.current = false
            return
        }
        dataContainer.updateNameDelay(dataKey, dataName)
    }, [dataContainer, dataName, dataKey])

    useEffect(() => {
        const inferredDelimiter = inferDelimiterType(raw)
        setInferredDelimiterType(inferredDelimiter)
        dataContainer.updateDataColumns(dataKey, raw, delimiterType || inferredDelimiterType)
    }, [raw, delimiterType, dataContainer, dataKey])

    useEffect(() => {
        dataContainer.updateNotesDelay(dataKey, notes)
    }, [notes, dataContainer, dataKey])

    return(
        <Subscribe to={[DataContainer, TutorialContainer]}>
          {(dc: DataContainer, tutorialContainer: TutorialContainer) => {
            const data = dc.getData(dataKey)
            const numCols = data?.columns.length || 0
            const numRows = data?.columns[0]?.raw.length || 0

            return data === undefined ? null :
                <SiderCollapsePanel
                    {...props}
                    header={data.nameWithType}
                    key={"dataKey"}
                >
                    <List style={{minWidth: "320px"}}>
                        <List.Item>
                            <SiderInput
                                readOnly={readOnly}
                                disabled={readOnly}
                                placeholder="Data Name"
                                onChange={n => setDataName(n.target.value)}
                                value={dataName}
                            />
                        </List.Item>
                        <List.Item>
                        <Popover
                            content={tutorial2.content}
                            visible={tutorialContainer.state.curStep === tutorial2.step}
                            placement={tutorial2.placement}
                        >
                            <TextArea
                                readOnly={readOnly}
                                disabled={readOnly}
                                rows={4}
                                placeholder="Paste data here"
                                onChange={t => {
                                    tutorialContainer.complete(tutorial2.step)
                                    setRaw(t.target.value)}}
                                value={raw}
                            />
                        </Popover>
                        </List.Item>
                        <List.Item>
                            <TextArea
                                readOnly={readOnly}
                                disabled={readOnly}
                                rows={1}
                                placeholder="Add data notes here"
                                onChange={t => setNotes(t.target.value)}
                                value={notes}
                            />
                        </List.Item>
                        <List.Item>
                            <DataDelimitorSelector
                                disabled={readOnly}
                                delimiterType={delimiterType || inferredDelimiterType}
                                handleChangeDelimiterType={setDelimiterType}
                            />
                        </List.Item>
                        <List.Item>
                            <Row style={{width: "100%"}}>
                                <Col span={12}>
                                    {`${numRows} ${numRows === 1 ? "row" : "rows"}`}
                                </Col>
                                <Col span={12}>
                                    {`${numCols} ${numCols === 1 ? "column" : "columns"}`}
                                </Col>
                            </Row>
                        </List.Item>
                            {data.columns.length > 0 ?
                            <Progress percent={100} size="small" /> :
                            <Progress percent={0} size="small" />}
                        <List.Item>
                            <DataColumnValidator
                                data={data}
                                readOnly={readOnly}
                                handleChangeDataFormatType={(c, f) => dc.updateDataFormatType(dataKey, c, f)}
                            />
                        </List.Item>
                        <List.Item>
                            <FullWidthButton
                                type="danger"
                                onClick={() => setShowDeleteModal(true)}
                                disabled={readOnly}
                            >
                                Delete
                            </FullWidthButton>
                        </List.Item>
                    </List>
                    {showDeleteModal &&
                        <DeleteModal
                            name={data.name}
                            onOk={() => dc.deleteData(data.key)}
                            onCancel={() => setShowDeleteModal(false)}
                            />
                    }
                </SiderCollapsePanel>
            }
        }
        </Subscribe>
    )
}

export default DataPanel
