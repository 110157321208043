import { Col, Row, Switch, Tooltip } from "antd"
import React from "react"
import { OptionItem, OptionLabel, OptionValueCol } from "../../style"

interface SwitchOptionProps {
    label: string
    leftLabel: string
    rightLabel: string
    selected: boolean
    onToggle: (checked: boolean) => void
}

const SwitchOption: React.FunctionComponent<SwitchOptionProps> = (props) => {
    const { label, leftLabel, rightLabel, selected, onToggle } = props

    return(
        <OptionItem>
            <Row style={{width: "100%"}}>
                <Col span={12}>
                    <Tooltip title={label}>
                        <OptionLabel>{label}</OptionLabel>
                    </Tooltip>
                </Col>
                <OptionValueCol span={12}>
                <Switch
                    checkedChildren={rightLabel}
                    unCheckedChildren={leftLabel}
                    onChange={onToggle}
                    checked={selected}
                />
                </OptionValueCol>
            </Row>
        </OptionItem>
    )
}

export default SwitchOption
