import { List, Popover } from "antd"
import React from "react"
import { container } from "tsyringe"
import { Subscribe } from "unstated"
import { ChartDataContainer } from "../../../containers/ChartDataContainer"
import { TutorialContainer } from "../../../containers/TutorialContainer"
import { tutorial6, tutorial7 } from "../../../tutorial/tutorialSteps"
import { ChartKey, ContainerBase, DataProps } from "../../../types"
import ChartColumnSelectorRow from "./ChartColumnSelectorRow"

interface ChartColumnSelectorProps {
    chartKey: ChartKey
    data?: ContainerBase<DataProps>
    readOnly: boolean
}

const ChartColumnSelector: React.FunctionComponent<ChartColumnSelectorProps> = (props) => {
    const { chartKey, data, readOnly } = props
    const cdc = container.resolve(ChartDataContainer)
    const chartData = cdc.getData(chartKey)

    const handleUpdateDataColumns = (
        chartDataContainer: ChartDataContainer,
        colType: "label" | "data",
        selected: string | string[],
    ) => {
        const selectedArr = typeof(selected) === "string" ? [selected] : selected
        const colsSelected = new Set(selectedArr)
        const cols = data ? data.columns.filter(c => colsSelected.has(c.name)) : []
        if (colType === "label" && cols.length === 1) {
            chartDataContainer.updateLabelColumns(chartKey, cols)
        } else if (colType === "data") {
            chartDataContainer.updateDataColumns(chartKey, cols)
        }
    }

    return(
        // TODO: why is this subscribed? only one way data!!
        <Subscribe to={[ChartDataContainer, TutorialContainer]}>
            {(chartDataContainer: ChartDataContainer, t: TutorialContainer) => {
                const colName = chartData?.labelColumns[0]?.name
                const colsSelected = colName ? [colName] : []
                const colsDataSelected = chartData ? chartData.dataColumns.map(c => c.name) : []
                const columns = data ? data.columns.map(c => c.name) : []
                return <React.Fragment>
                    <List.Item>
                        <Popover
                            content={tutorial6.content}
                            visible={t.state.curStep === tutorial6.step}
                            placement={tutorial6.placement}
                        >
                            <ChartColumnSelectorRow
                                name="Label"
                                multiple={false}
                                selected={colsSelected}
                                columns={columns}
                                onClick={() => t.complete(tutorial6.step)}
                                readOnly={readOnly}
                                handleOnChange={cols =>
                                    handleUpdateDataColumns(chartDataContainer, "label", cols)}
                            />
                        </Popover>
                    </List.Item>
                    <List.Item>
                        <Popover
                            content={tutorial7.content}
                            visible={t.state.curStep === tutorial7.step}
                            placement={tutorial7.placement}
                        >
                            <ChartColumnSelectorRow
                                name="Column"
                                multiple={true}
                                selected={colsDataSelected}
                                columns={columns}
                                readOnly={readOnly}
                                onClick={() => t.complete(tutorial7.step)}
                                handleOnChange={cols =>
                                    handleUpdateDataColumns(chartDataContainer, "data", cols)
                                }
                            />
                        </Popover>
                    </List.Item>
                </React.Fragment>
            }
        }
        </Subscribe>
    )
}

export default ChartColumnSelector
