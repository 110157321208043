import { Layout, Typography } from "antd"
import styled from "styled-components"

export const AppFooter = styled(Layout.Footer)`
    text-align: center;
`

export const FooterText = styled(Typography.Text)`
    font-size: 12px;
`
