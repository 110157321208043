import { Table } from "antd"
import React from "react"
import "react-datasheet/lib/react-datasheet.css"
import XLSX from "xlsx"
import { AppIcon, IconContainer } from "../../../style"
import { DataColumn } from "../../../types"
import { dataColumnsToTableColumns, dataColumnsToTableRows } from "../../../utils/dataUtils"
import { transpose } from "../../../utils/utils"

interface DataTableProps {
    columns: DataColumn[]
}

const DataTable: React.FunctionComponent<DataTableProps> = (props) => {
    const { columns } = props

    const dataSource = dataColumnsToTableRows(columns)
    const dataColumns = dataColumnsToTableColumns(columns)

    const writeToExcel = () => {
        const excelHeaders = [columns.map(c => c.name)]
        const excelData = transpose(columns.map(c => c.raw))
        const excelDataWithHeaders = excelHeaders.concat(excelData)
        const worksheet = XLSX.utils.aoa_to_sheet(excelDataWithHeaders)
        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, worksheet, "SheetJS")
        XLSX.writeFile(wb, "out.xlsx")
    }

    return(
        <React.Fragment>
            <Table
                dataSource={dataSource}
                columns={dataColumns}
                rowKey="key"
            />
            {columns.length > 0 &&
                <IconContainer>
                    <AppIcon title="export" type="export" onClick={writeToExcel}/>
                </IconContainer>
            }
        </React.Fragment>
    )
}

export default React.memo(DataTable)
