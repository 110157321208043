import { injectable, singleton } from "tsyringe"
import { Container } from "unstated"
import { Analytics } from "aws-amplify"

interface TutorialContainerProps {
    curStep: number
}

@injectable()
@singleton()
export class TutorialContainer extends Container<TutorialContainerProps> {
    public state = {
        curStep: -1,
    }

    public constructor() {
        super()
    }

    public init = () => {
        Analytics.record({name: "Tutorial Begin"})
        this.setState({curStep: 1})
    }

    public clear = () => {
        if (this.state.curStep !== -1) {
            Analytics.record({
                name: "Tutorial Abandonded",
                attributes: {step: this.state.curStep}
            })
            this.setState({curStep: -1})
        }
    }

    public complete = (step: number) => {
        if (this.state.curStep === step) {
            Analytics.record({name: "Tutorial Completed"})
            this.setState(() => ({curStep: step + 1}))
        }
    }
}
