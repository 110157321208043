import { debounce, isEqual } from "lodash"
import { container } from "tsyringe"
import { Container } from "unstated"
import { ContainerBase } from "../types"
import { MetaContainer } from "./MetaContainer"

interface DataState<T> {
    datas: Array<ContainerBase<T>>
}

export abstract class BaseContainer<T> extends Container<DataState<ContainerBase<T>>> {
    public static lastSelected?: string

    public state = {
        datas: Array<ContainerBase<T>>(),
      }

    protected abstract prefix: string
    protected abstract emptyData: (id: string | undefined) => ContainerBase<T>

    private numDataCreated = 0
    private metaContainer = container.resolve(MetaContainer)

    public constructor() {
        super()
        this.setState({datas: []})
    }

    public getDataByIndex = (index: number) => this.state.datas[index]

    public getDatas = () => this.state.datas

    public getData = (key: string): Readonly<ContainerBase<T>> | undefined =>
        this.state.datas.find(d => d.key === key)

    public getDataIndex = (key: string) => this.state.datas.findIndex(d => d.key === key)

    public getAll = () => this.state.datas

    public loadData = (datas: Array<ContainerBase<T>>) => this.setState({datas})

    public addData = (data: ContainerBase<T>) => {
        BaseContainer.lastSelected = data.key
        this.metaContainer.updateLastSelected(data.key)
        const datas = this.state.datas.concat(data)
        this.setState({datas})
    }

    public deleteData = (deleteKey: string) => {
        const datas = this.state.datas.filter(d => d.key !== deleteKey)
        this.setState({datas})
    }

    public updateData = (updated: ContainerBase<T>, callback?: () => void) => {
        const dataIdx = this.state.datas.findIndex(d => d.key === updated.key)
        if (dataIdx !== -1) {
            const original = this.state.datas[dataIdx]
            if (!isEqual(original, updated)) {
                const datas = this.state.datas.map((orig, idx) => idx === dataIdx ? updated : orig)
                this.setState({datas}, callback)
            }
        }
    }

    public updateNotes = (key: string, notes: string, callback?: () => void) => {
        const data = this.getData(key)
        if (data) {
            const updated = {...data, notes}
            this.updateData(updated, callback)
        }
    }

    public addEmptyData = () => {
        const partial = this.emptyData(undefined)
        this.numDataCreated += 1
        const nameWithType = this.getNameWithType(`${this.numDataCreated}`)
        const data = {...partial, nameWithType}
        this.addData(data)
    }

    public reset = () => {
        this.numDataCreated = 0
        this.state.datas = [] // setState doesn't work on empty list?
    }

    public updateName = (key: string, name: string, callback?: () => void) => {
        const data = this.getData(key)
        if (data) {
            const nameWithType = this.getNameWithType(name)
            const updated = {...data, name, nameWithType}
            this.updateData(updated, callback)
        }
    }

    // tslint:disable-next-line: member-ordering
    public updateNameDelay = debounce(this.updateName, 1000)

    // tslint:disable-next-line: member-ordering
    public updateNotesDelay = debounce(this.updateNotes, 1000)

    protected getNameWithType = (name: string): string => {
        // e.g. [Data] My Data
        return `[${this.prefix}] ${name}`
    }

}
