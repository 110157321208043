import { Collapse } from "antd"
import React from "react"
import { ChartDefaults } from "../../../../../../config"
import { OptionsCollapsePanel } from "../../../../../../style"
import { ChartKey } from "../../../../../../types"
import { buildOption as b } from "../../utils"

interface TitleProps {
    chartKey: ChartKey
}

const Title: React.FunctionComponent<TitleProps> = (props) => {
    const { chartKey } = props
    const header = "Title"
    const prefix = "title"

    return(
        <Collapse bordered={false} style={{width: "100%"}} key={`${chartKey}${prefix}`}>
            <OptionsCollapsePanel header={header} key={"header"}>
                {b(chartKey, "Display Title", "chartjs.title.display", "boolean")}
                {b(chartKey, "Text", "chartjs.title.text", "string")}
                {b(chartKey, "Font Size", "chartjs.title.fontSize", "number")}
                {/* ignore fullWidth, doesn't seem to do anything */}
                {b(chartKey, "Full Width", "chartjs.title.fullWidth", "boolean")}
                {b(
                    chartKey,
                    "Font Family",
                    "chartjs.title.fontFamily",
                    "string",
)
                }
                {b(chartKey, "Font Color", "chartjs.title.fontColor", "color")}
                {b(chartKey, "Font Style", "chartjs.title.fontStyle", "string")}
                {b(
                    chartKey,
                    "Position",
                    "chartjs.title.position",
                    "enum",
                    ["left", "right", "top", "bottom", "chartArea"])
                }
                {b(chartKey, "Padding", "chartjs.title.padding", "number")}

            </OptionsCollapsePanel>
        </Collapse>
    )
}

export default Title
