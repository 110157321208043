import { Layout, Typography } from "antd"
import styled from "styled-components"
import appColors from "./colors"

export const AppTitle = styled(Typography.Text)`
    color: white;
    font-size: 16px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    margin-right: 10px;
    float: left;
`

export const AppHeader = styled(Layout.Header)`
    height: fit-content;
    background-color: ${appColors.blue};
    box-shadow: 0px 3px ${appColors.tan};
    position: fixed;
    z-index: 1;
    width: 100%;
    padding: 0px;
`
export const AppHeaderIcon = styled.img`
    float: left;
    height: 64px;
    padding: 10px;
`

export const AppLayout = styled(Layout)`
    background-color: ${appColors.mainBackground};
    overflow: auto;
`

export const LandingAppLayout = styled(AppLayout)`
    padding-top: 64px;
    min-height: 100vh;
    width: 100%;
`

export const ExploreAppLoayout = styled(Layout)`
    background-color: ${appColors.mainBackground};
    margin-left: 5%;
    margin-right: 5%;
`

export const ChartItAppLayout = styled(AppLayout)`
    padding-top: 96px;
`
