import { Button, Modal, Progress, Typography } from "antd"
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { AppConfigs } from "../../config"
import { ChartKey } from "../../types"

const { Text, Paragraph } = Typography

interface HtmlModalProps {
    group: string
    chartId: string
    chartKey: ChartKey
    readOnly: boolean
    onCancel: () => void
    saveChart: () => Promise<string | undefined>
}

const HtmlModal: React.FunctionComponent<HtmlModalProps> = (props) => {
    const {group, chartId, chartKey, onCancel, readOnly, saveChart } = props

    const scriptText = `<script src="${AppConfigs.apiFile}" path="${group}/${chartId}/${chartKey}" attachId="myChartId"></script>`
    const divText = `<div id="myChartId"></div>`

    const [url, setUrl] = useState<string>()
    const [isLoading, setIsLoading] = useState(false)
    const [isError, setIsError] = useState(false)

    useEffect(() => {
        if (readOnly) {
            const u = `${AppConfigs.basePath}${AppConfigs.chartsPath}/${group}/${chartId}/${chartKey}`
            setUrl(u)
        }
    }, [chartId, chartKey, group, readOnly])

    useEffect(() => {
        if (url) { setIsError(false) }
    }, [url])

    const handleOnCreate = () => {
        setIsLoading(true)
        saveChart()
            .then(u => {
                if (u !== undefined) {
                    setUrl(u)
                } else {
                    setIsError(true)
                }
            })
            .finally(() => setIsLoading(false))
    }
    // TODO: if not public, don't save!!
    return (
        <Modal
            title="Create HTML Snippet"
            visible={true}
            cancelText="OK"
            onCancel={onCancel}
            okButtonProps={{ style: { display: "none" } }}
        >
            <Paragraph>
                Chart It lets you create and embed charts into your own website.
                First, save the chart. This will create a
                <Text strong> public link</Text> containing your chart and data.

                <br/><br/>
                <Button
                    type="primary"
                    onClick={handleOnCreate}
                    disabled={isLoading || url !== undefined}
                >
                    Save
                </Button>
                <span> </span>
                {isLoading === true || url !== undefined  || isError ?
                    <Progress type="circle" percent={url === undefined ? 0 : 100} width={30} status={isError ? "exception" : undefined}/> :
                    null
                }
                <span> </span>
                {url !== undefined ? url : null}

                <br/><br/>
                Then copy this script into the &lt;head&gt; of your html:
                <br/><br/>

                <Text copyable={{text: scriptText}}>
                    <code>
                       {scriptText}
                    </code>
                </Text>

                <br/><br/>

                Then just put a &lt;div&gt; element with the id specified in the script:
                <br/><br/>
                <Text copyable={{text: divText}}>
                    <code>
                        {divText}
                    </code>
                </Text>

                <br/><br/>
                <Text>See the <Link target="_blank" to={AppConfigs.apiPath}>
                    API documentation</Link> for more information</Text>
            </Paragraph>
        </Modal>
    )
}

export default HtmlModal
