import { minBy } from "lodash"
import moment from "moment"

const dateFormats = [
    "dddd, MMMM D, YYYY",               // Tuesday, Dec 3, 2019
    "YYYY-MM-DD",                       // 2019-12-03
    "M/D",                              // 2/3
    "M/D/YY",                           // 2/3/19
    "MM/DD/YY",                         // 02/03/19
    "D-MMM",                            // 3-Dec
    "D-MMM-YY",                         // 3-Dec-19
    "DD-MMM-YY",                        // 03-Dec-19
    "MMM-YY",                           // Dec-19
    "MMMM-YY",                          // December-19
    "MMMM D, YYYY",                     // December 3, 2019
    "M/D/YY H:m A",                     // 2/3/19 7:22 AM
    "M/D/YY H:mm",                      // 2/3/19 19:22
    // unsupported,                     // D
    // unsupported,                     // D-19
    "D/M/YYYY",                         // 2/3/2019
    "D-MMM-YYYY",                       // 3-Dec-2019
    "ddd, DD MMM YYYY HH:mm:ss ZZ",     // Fri, 27 Jan 2017 00:00:00 -0600
    "YYYY",                             // 2019
]

export const findBestDateFormat = (col: string[]) => {
    const scores: Array<{numInvalid: number, format: string}> = []
    dateFormats.some(format => {
        const numInvalid = col.map(s => moment(s, format, true).isValid()).filter(i => i === false).length
        scores.push({numInvalid, format})
        if (numInvalid === 0) {
            return {numInvalid, format}
        }
        return undefined
    })
    const best = minBy(scores, "numInvalid") || {numInvalid: col.length, format: dateFormats[0]}
    return best
}

export const inferDateFormat = (col: string[]) => {
    const containsUndefined = col.some(c => c === undefined || c === "")
    if (containsUndefined || col.length === 0) { return undefined }

    const out = dateFormats.find(format => {
        const invalid = col.find(c => !moment(c, format, true).isValid())
        return !invalid
    })
    return out
}
