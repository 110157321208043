import { cloneDeep, countBy, every, range, uniq } from "lodash"
import moment from "moment"
import shortid from "shortid"
import { DataColumn, DataFormat, DataFormatType, DataProps, DelimiterType } from "../types"
import { findBestDateFormat, inferDateFormat } from "./dateUtils"
import { isNumeric, transpose } from "./utils"

export const emptyDataProps = (key?: string): DataProps => ({
    name: "",
    nameWithType: "",
    raw: "",
    key: key || shortid.generate(),
    notes: "",
    columns: [],
    delimiterType: DelimiterType.tab,
})

export const textToColumns = (
    text: string, dataColumns: {[x: string]: DataFormat}, delimiterType: DelimiterType | undefined,
): DataColumn[] => {
    const delimiter = (
        delimiterType === undefined ? inferDelimiterType(text) : delimiterMapping(delimiterType)
    )

    const rows = text.split("\n").filter(c => c.length > 0)

    if (rows.length === 0) { return [] }

    const textRows = transpose(rows.map((r) => r.split(delimiter)))
    const data = textRows.map(row => {
        const name = row[0]
        const raw = row.slice(1)
        const format = dataColumns[name] || inferFormat(raw)
        const c = {
            name,
            raw,
            key: shortid.generate(),
            invalid: 0,
            ...format,
        }
        return updateDataFormatType(c, format.dataFormat)
    })
    return data
}

const delimiterMapping = (delimiterType: DelimiterType) =>
    delimiterType === DelimiterType.comma ? "," :
    delimiterType === DelimiterType.pipe ? "|" :
    delimiterType === DelimiterType.tab ? "\t" : "\n"

export const inferDelimiterType = (raw: string) => {
    // counts potential delimiters per row. if delimiter count is the same in every row
    // and greater than 0, use that, else use last DelimiterType
    const rows = raw.split("\n").filter(r => r !== "")

    for (const dKey of Object.keys(DelimiterType)) {
        const delimiterType = dKey as DelimiterType
        const delimiter = delimiterMapping(delimiterType)
        const delimiterCounts = countBy(rows, r => r.split(delimiter).length)
        const delimiterKeys = uniq(Object.keys(delimiterCounts))
        if (delimiterKeys.indexOf("1") === -1 && delimiterKeys.length < 2) {
            return delimiterType
        }
    }
    return DelimiterType.tab
}

const inferFormat = (array: string[]): DataFormat => {
    if (array.length === 0) { return { dataFormat: DataFormatType.string } }

    const df = inferDateFormat(array)
    if (df) {
        return { dataFormat: DataFormatType.date, dateFormat: df }
    }

    const allNumeric = every(array.map(val => isNumeric(val)))

    return allNumeric ?
        { dataFormat: DataFormatType.number } :
        { dataFormat: DataFormatType.string }
}

export const updateDataFormatType = (
    original: DataColumn,
    dataFormat: DataFormatType,
): DataColumn => {
    const updated = cloneDeep(original)
    updated.dataFormat = dataFormat
    let invalid = 0
    switch (dataFormat) {
        case DataFormatType.number: {
            invalid = updated.raw.filter(d => !isNumeric(d)).length
            updated.dateFormat = undefined
            break
        }
        case DataFormatType.date: {
            const df = findBestDateFormat(updated.raw)
            updated.dateFormat = df.format
            invalid = df.numInvalid
            break
        }
        case DataFormatType.string: {
            invalid = 0
            updated.dateFormat = undefined
            break
        }
    }
    return {...updated, invalid}
}

const dataValueToString = (a: any) =>
    a instanceof Date ? a.toLocaleString() :
    typeof(a) === "number" ? a.toLocaleString() : a

export const dataColumnsToTableRows = (columns: DataColumn[]) => {
    if (columns.length === 0) { return [] }
    const numRows = columns[0].raw.length
    const items = range(0, numRows).map(idx => columns.map(c => (
        {key: shortid.generate(), [c.key]: {value: formatRaw(c.raw[idx], c.dataFormat, c.dateFormat)}}),
    ))
    const out = items.map(i => i.reduce((prev, cur) => ({...prev, ...cur })))
    return out
}

const compareDataFormattedType = <T = number|Date|string>(a: T, b: T) => {
    if (a instanceof Date && b instanceof Date) {
        return a.getTime() - b.getTime()
    }

    if (typeof(a) === "string" && typeof(b) === "string") {
        return a.localeCompare(b)
    }

    if (typeof(a) === "number" && typeof(b) === "number") {
        return a - b
    }

    return 0
}

export const dataColumnsToTableColumns = (columns: DataColumn[]) =>
    columns.map(c => (
        {
            title: c.name,
            dataIndex: `${c.key}.value`,
            key: c.key,
            render: dataValueToString,
            sorter: (a: any, b: any) => compareDataFormattedType(a[c.key].value, b[c.key].value),
        }),
    )

export const formatRaw = (raw: string, dataFormat: DataFormatType, dateFormat?: string) => {
    if (dataFormat === DataFormatType.date && dateFormat) {
        return moment(raw, dateFormat, true).toDate()
    } else if (dataFormat === DataFormatType.number) {
        const clean = raw ? raw.replace(/[ ,$ƒ₼¥₱¢£₡€៛R]/g, "") : raw
        return parseFloat(clean)
    }
    return raw
}
