import { Button, Modal, Progress, Typography } from "antd"
import React, { useState } from "react"

const { Text, Paragraph } = Typography

interface ShareModalProps {
    placeholderUrl: string
    readOnly: boolean
    saveChart: () => Promise<string | undefined>
    onCancel: () => void
}

const ShareModal: React.FunctionComponent<ShareModalProps> = (props) => {
    const { placeholderUrl, readOnly, saveChart, onCancel} = props

    const [url, setUrl] = useState<string | undefined>(readOnly ? placeholderUrl : undefined)
    const [isLoading, setIsLoading] = useState(false)

    const [isError, setIsError] = useState(false)

    const handleOnCreate = () => {
        setIsLoading(true)
        saveChart()
            .then(u => {
                if (u === undefined) {
                    setIsError(true)
                } else {
                    setUrl(u)
                    setIsError(false)
            }})
        .finally(() => setIsLoading(false))
    }

    return(
        <Modal
            title="Share Chart"
            visible={true}
            okText="Share"
            onCancel={onCancel}
            cancelText="OK"
            okButtonProps={{ style: { display: "none" }}}
        >
            <Paragraph>Sharing will create a <Text strong>public link</Text> containing your chart and data</Paragraph>
            <Paragraph>Anyone with this link will be able to view, but not edit your data</Paragraph>
            <Paragraph>Are you sure you want to continue?</Paragraph>

            <Button
                type="primary"
                onClick={handleOnCreate}
                disabled={isLoading || url !== undefined || readOnly}
            >
                Share
            </Button>
            <span> </span>
            {url ?
                <Progress
                    type="circle"
                    percent={url === undefined ? 0 : 100} width={30}
                    status={isError ? "exception" : undefined}
                /> : null
            }
            <span> </span>
            <Text disabled={url === undefined} copyable={url !== undefined}>{url || placeholderUrl}</Text>
        </Modal>
    )
}

export default ShareModal
