import { Divider, Layout, Typography } from "antd"
import React, { useEffect } from "react"
import MainFooter from "../components/footer"
import MainHeader from "../components/header"
import { LandingAppLayout } from "../style"
import ApiDemo from "./ApiDemo"
import LandingDemo from "./LandingDemo"
import LandingFaq from "./LandingFaq"
import LandingFeatures from "./LandingFeatures"
import LandingSplash from "./LandingSplash"
import LandingPricing from "./LandingSplash/LandingPricing"
import LandingSplashChart from "./LandingSplash/LandingSplashChart"
import { LandingBlock } from "./style"

interface LandingAppProps {
    scrollId?: string
}

const LandingApp: React.FunctionComponent<LandingAppProps> = (props) => {
    const { scrollId } = props
    useEffect(() => {
        const element = scrollId ? document?.getElementById(scrollId) : null
        if (element !== null) {
            setTimeout(() => {
                window.scrollTo({
                    behavior: "smooth",
                    top: element ? element.offsetTop : 0,
                })
            }, 2000)
        }
    }, [scrollId])

    return(
        <Layout>
            <MainHeader/>
            <LandingAppLayout>
                <LandingBlock>
                    <LandingSplash/>
                </LandingBlock>
                <Layout.Content>
                    <Divider/>
                    <LandingBlock >
                        <LandingDemo/>
                    </LandingBlock>
                    <Divider/>
                    <LandingBlock>
                        <Typography.Title level={2}>
                            Now supporting Geo-Charts
                        </Typography.Title>
                        <LandingSplashChart group="explore" chartId="co2-emissions-map" chartKey="Chart" />
                    </LandingBlock>
                    <LandingBlock>
                        <Divider/>
                        <LandingFeatures/>
                        <ApiDemo/>
                        <Divider/>
                        {/* <LandingPricing/> */}
                        <Divider/>
                        <LandingFaq/>
                    </LandingBlock>
                </Layout.Content>
                <MainFooter/>
            </LandingAppLayout>
        </Layout>
    )
}

export default LandingApp
