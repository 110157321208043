import React, { useCallback, useEffect } from "react"
import { ChartType, DataColumn, GoogleOptions } from "../../../types"
import { drawChart } from "../../../utils/drawChart"

interface GoogleChartProps {
    labelColumns: DataColumn[],
    dataColumns: DataColumn[],
    chartType?: ChartType,
    width: number,
    height: number,
    options: GoogleOptions,
    setChartUri: (uri: string) => void
}

const GoogleChart: React.FunctionComponent<GoogleChartProps> = (props) => {
    const {
        labelColumns,
        dataColumns,
        chartType,
        width,
        height,
        options,
        setChartUri,
      } = props

    const chartRef = React.createRef<HTMLDivElement>()

    const redrawChart = useCallback((ref: React.RefObject<HTMLDivElement>) => {
        if (labelColumns.length > 0 && dataColumns.length > 0 && chartType && chartRef.current) {
          const chartProps = {
            chartRef: ref,
            labelColumns,
            dataColumns,
            chartType,
            width,
            height,
            options,
          }
          // @ts-ignore
          drawChart(chartProps, c => setChartUri(c.getImageURI()))
        } else {
          if (chartRef.current) { chartRef.current.innerHTML = "" }
        }
      }, [chartRef, width, height, options, chartType, dataColumns, labelColumns])

    useEffect(() => {
        redrawChart(chartRef)
      }, [chartRef, redrawChart])

    return(
        <div ref={chartRef}></div>
    )
}

export default GoogleChart
