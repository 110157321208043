import { Button, Card, Collapse, Icon, Input, Layout, Row } from "antd"
// @ts-ignore
import { rubberBand } from "react-animations"
import styled, { keyframes } from "styled-components"
import appColors from "../colors"

export const MainLayout = styled(Layout)`
    height: 100vh;
    width: fit-content;
    min-width: 99vw;
`

export const AppCard = styled(Card)`
    margin: 10px;
    width: fit-content;
    display: flex;
    flex-direction: column;
`

export const ChartCard = styled(AppCard)`
    display: flex;
    padding-bottom: 25px;
`

export const DataManagerIcon = styled(Icon)`
    font-size: 24px;
`

export const WideRow = styled(Row)`
    width: 100%;
`

export const SiderInput = styled(Input)`
    max-width: 500px;
`

export const SiderCollapse = styled(Collapse)`
    min-width: 100%;
`

export const SiderCollapsePanelBase = styled(Collapse.Panel)`
    max-width: 355px;
    overflow: scroll;
    width: fit-content;
    scrollbar-width: thin;
`

export const SiderCollapsePanel = styled(SiderCollapsePanelBase)`
    min-width: 355px;
    overflow: auto;
    & > div { width: 100%; }
`

export const OptionsCollapsePanel = styled(SiderCollapsePanelBase)`
    width: 100%;
    overflow: auto;
    & > div { width: 100%; }
`

export const FullWidthButton = styled(Button)`
    width: 100%;
`

export const AppSider = styled(Layout.Sider)`
    height: calc(100vh - 106px);
    overflow: auto;
    margin-bottom: 10px;
    width: fit-content;
`

export const AppContent = styled(Layout.Content)`
    overflow-x: scroll !important;
    width: 0px;
`

const bounceAnimation = keyframes`${rubberBand}`

export const BouncyDiv = styled.div`
  animation: 1s ${bounceAnimation} infinite;
`

export const LoadingBackground = styled.div`
    height: 100vh;
    width: 100hh;
    display: flex;
    background-color: ${appColors.mainBackground};
    justify-content: center;
    align-items: center;
`

export const AppIcon = styled(Icon)`
    padding: 5px;
    font-size: 16px;
`

export const AppIconSelected = styled(AppIcon)`
    color: ${appColors.green};
`

export const AppImage = styled.img`
    padding: 2px;
    background-color: ${appColors.blue};
    height: 50vh;
`

export const IconContainer = styled.div`
    position: absolute;
    right: 0;
`
