import { Collapse } from "antd"
import React from "react"
import { ChartDefaults } from "../../../../../../config"
import { OptionsCollapsePanel } from "../../../../../../style"
import { ChartKey } from "../../../../../../types"
import { buildOption as b } from "../../utils"

interface LegendProps {
    chartKey: ChartKey
}

const Legend: React.FunctionComponent<LegendProps> = (props) => {
    const { chartKey } = props
    const header = "Legend"
    const prefix = "legend"

    return(
        <Collapse bordered={false} style={{width: "100%"}} key={`${chartKey}${prefix}`}>
            <OptionsCollapsePanel header={header} key={"header"}>
            {b(
                    chartKey,
                    "Display",
                    "chartjs.legend.display",
                    "boolean",
                    )
                }
                {b(
                    chartKey,
                    "Align",
                    "chartjs.legend.align",
                    "enum",
                    ["center", "end", "start"],
                    )
                }
                {b(
                    chartKey,
                    "Position",
                    "chartjs.legend.position",
                    "enum",
                    ["top", "left", "bottom", "right"],
                    )
                }
                {b(
                    chartKey,
                    "Full Width",
                    "chartjs.legend.fullWidth",
                    "boolean",
                    )
                }
                {b(
                    chartKey,
                    "Reverse",
                    "chartjs.legend.reverse",
                    "boolean",
                    )
                }
            </OptionsCollapsePanel>
        </Collapse>
    )
}

export default Legend
