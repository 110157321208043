import { Collapse } from "antd"
import React from "react"
import { OptionsCollapsePanel } from "../../../../../../style"
import { ChartKey } from "../../../../../../types"
import { buildOption as b } from "../../utils"

interface ColorAxisProps {
    chartKey: ChartKey
}

const ColorAxis: React.FunctionComponent<ColorAxisProps> = (props) => {
    const { chartKey } = props

    return(
        <Collapse bordered={false}>
            <OptionsCollapsePanel header="Color Axis" key={"header"}>
                {b(chartKey, "Min Value", "google.colorAxis.minValue", "number", undefined)}
                {b(chartKey, "Max Value", "google.colorAxis.maxValue", "number", undefined)}
                {b(chartKey, "Colors", "google.colorAxis.colors", "tags", undefined)}
            </OptionsCollapsePanel>
        </Collapse>
    )
}

export default ColorAxis
