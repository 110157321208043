import { Collapse } from "antd"
import React from "react"
import { ChartDefaults } from "../../../../../../config"
import { OptionsCollapsePanel } from "../../../../../../style"
import { ChartKey } from "../../../../../../types"
import { buildOption as b } from "../../utils"

interface ChartLegendProps {
    chartKey: ChartKey
}

const ChartLegend: React.FunctionComponent<ChartLegendProps> = (props) => {
    const { chartKey } = props
    const header = "Chart Legend"
    const prefix = "legend" // note: Types have incorrect prefix of chartLegend
    return(
        <Collapse bordered={false} style={{width: "100%"}} key={`${chartKey}${prefix}`}>
            <OptionsCollapsePanel header={header} key={"header"}>
                {b(chartKey, "Alignment", `google.${prefix}.alignment`, "enum", ["automatic", "start", "center", "end"])}
                {b(chartKey, "Max Lines", `google.${prefix}.maxLines`, "number")}
                {b(chartKey, "Position", `google.${prefix}.position`, "enum", ["bottom", "left", "in", "none", "right", "top"])}
            </OptionsCollapsePanel>
        </Collapse>
    )
}

export default ChartLegend
