import { Collapse } from "antd"
import React, { useEffect, useState } from "react"
import { container } from "tsyringe"
import { ChartDataContainer } from "../../../../../../containers/ChartDataContainer"
import { OptionsCollapsePanel } from "../../../../../../style"
import { ChartKey } from "../../../../../../types"
import { buildOption as b } from "../../utils"
import SwitchOption from "./SwitchOption"
import YAxis from "./YAxis"

interface AxisProps {
    chartKey: ChartKey
    selected: number
}

const Axis: React.FunctionComponent<AxisProps> = (props) => {
    const { chartKey, selected } = props
    const [axisOptionPath, setAxisOptionPath] = useState("")

    const [isRAxis, setIsRightAxis] = useState(false)
    const [rAxisIdx, setRAxisIdx] = useState(0)

    useEffect(() => {
        const a = `chartjs.chartDataSets[${selected}].yAxisID`
        setAxisOptionPath(a)
        const isR = chartContainer.getData(chartKey)?.options.chartjs.chartDataSets[selected]?.yAxisID === "right"
        setIsRightAxis(isR)
        setRAxisIdx(isR ? 1 : 0)
    }, [selected])

    const chartContainer = container.resolve(ChartDataContainer)
    const toggle = (checked: boolean) => {
        const d = chartContainer.getData(chartKey)
        if (d !== undefined) {
            if (checked) {
                // move from left to right
                const showR = () => chartContainer.updateOption(chartKey, "chartjs.scales.yAxes[1].display", true)
                chartContainer.updateOption(chartKey, axisOptionPath, "right", showR)
            } else {
                // move from right to left
                const hideR = () => {
                    const noR = chartContainer.getData(chartKey)?.options.chartjs.chartDataSets.every(c => c.yAxisID !== "right")
                    if (noR) {
                        chartContainer.updateOption(chartKey, "chartjs.scales.yAxes[1].display", false)
                    }
                }
                chartContainer.updateOption(chartKey, axisOptionPath, "left", hideR)
            }
            setIsRightAxis(checked)
            setRAxisIdx(checked ? 1 : 0)
        }
    }

    return(
        <Collapse bordered={false} style={{width: "100%"}} key={`${chartKey} axis`}>
            <OptionsCollapsePanel style={{width: "100%"}} header={`Y-Axes`} key="y-axes">
                <SwitchOption label="Axis" leftLabel="L" rightLabel="R" selected={isRAxis} onToggle={toggle} />
                <YAxis chartKey={chartKey} rAxisIdx={rAxisIdx}/>
            </OptionsCollapsePanel>
            <OptionsCollapsePanel style={{width: "100%"}} header={`X-Axes`} key="x-axes">
                {b(chartKey, "Display Label", `chartjs.scales.xAxes[0].scaleLabel.display`, "boolean")}
                {b(chartKey, "Display Gridlines", `chartjs.scales.xAxes[0].gridLines.display`, "boolean")}
                {b(chartKey, "Stacked", `chartjs.scales.xAxes[0].stacked`, "boolean")}
                {b(chartKey, "Auto Skip", `chartjs.scales.xAxes[0].autoSkip`, "boolean")}
                {/*
                // set automatically to category or time depending if label is date format or not
                {b(
                    chartKey,
                    "Type",
                    `chartjs.scales.xAxes[0].type`,
                    "enum",
                    ["category", "linear", "logarithmic", "time", "radialLinear"], false)}
                */}
                {b(
                    chartKey,
                    "Time Format",
                    `chartjs.scales.xAxes[0].time.unit`,
                    "enum",
                    ["millisecond", "second", "minute", "hour", "day", "week", "month", "quarter", "year"])
                }
                {b(chartKey, "Precision", `chartjs.scales.xAxes[0].ticks.precision`, "number")}
                {b(chartKey, "Min", `chartjs.scales.xAxes[0].ticks.min`, "number")}
                {b(chartKey, "Max", `chartjs.scales.xAxes[0].ticks.max`, "number")}
                {b(chartKey, "Step Size", `chartjs.scales.xAxes[0].ticks.stepSize`, "number")}
                {b(chartKey, "Axis Label", `chartjs.scales.xAxes[0].scaleLabel.labelString`, "string")}
            </OptionsCollapsePanel>
        </Collapse>

    )
}

export default Axis
