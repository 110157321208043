import React from "react"
import { Link } from "react-router-dom"
import headerLogo from "../../images/logo-white-transparent-128.png"
import { AppHeader, AppHeaderIcon, AppTitle } from "../../style"

const MainHeader: React.FunctionComponent = () => {
    return(
        <AppHeader>
            <AppHeaderIcon src={headerLogo} />
            <Link to="/"><AppTitle>Chart It</AppTitle></Link>
            <div style={{float: "right", height: "100%", paddingRight: "10px"}}>
                <Link to="/explore"><AppTitle>Explore</AppTitle></Link>
                <AppTitle editable={false}>|</AppTitle>
                <Link to="/app"><AppTitle>Create</AppTitle></Link>
            </div>
        </AppHeader>
    )
}

export default MainHeader
