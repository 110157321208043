import { Input, List } from "antd"
import React, { useEffect, useState } from "react"
import { container } from "tsyringe"
import { Subscribe } from "unstated"
import { ChartDataContainer } from "../../../containers/ChartDataContainer"
import { DataContainer } from "../../../containers/DataContainer"
import { MetaContainer } from "../../../containers/MetaContainer"
import { FullWidthButton, SiderCollapsePanel } from "../../../style"
import { ChartKey } from "../../../types"
import DeleteModal from "../../modals/DeleteModal"
import ChartColumnSelector from "./ChartColumnSelector"
import ChartDataSelector from "./ChartDataSelector"
import ChartSelector from "./ChartSelector"

interface ChartPanelProps {
    chartKey: ChartKey
}

const ChartPanel: React.FunctionComponent<ChartPanelProps> = (chartProps) => {
    const {
        chartKey,
        ...props
    } = chartProps

    const chartDataContainer = container.resolve(ChartDataContainer)

    const initialChart = chartDataContainer.getData(chartKey)

    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [chartName, setChartName] = useState<string>()

    const metaContainer = container.resolve(MetaContainer)
    const readOnly = metaContainer.state.readOnly

    useEffect(() => {
        if (initialChart?.name) { setChartName(initialChart.name) }
    }, [initialChart])

    useEffect(() => {
        if (chartName !== undefined) { chartDataContainer.updateNameDelay(chartKey, chartName) }
    }, [chartName, chartKey, chartDataContainer])

    return(
        <Subscribe to={[DataContainer, ChartDataContainer]}>
            {(dataContainer: DataContainer, cdc: ChartDataContainer) => {
                const chart = cdc.getData(chartKey)
                if (chart === undefined) { return null }
                const data = chart.dataKey ? dataContainer.getData(chart.dataKey) : undefined

                return (
                <SiderCollapsePanel
                    {...props}
                    header={chart.nameWithType}
                    key={chartKey}
                    >
                    <List style={{width: "100%", minWidth: "320px"}}>
                        <List.Item>
                            <Input
                                placeholder="Chart Name"
                                onChange={p => setChartName(p.target.value)}
                                value={chartName}
                                disabled={readOnly}
                            />
                        </List.Item>
                        <List.Item>
                            <ChartDataSelector chartKey={chartKey} readOnly={readOnly}/>
                        </List.Item>
                        <List.Item>
                            <ChartSelector chartKey={chartKey} readOnly={readOnly}/>
                        </List.Item>
                            <ChartColumnSelector chartKey={chartKey} data={data} readOnly={readOnly}/>
                        <List.Item>
                            <FullWidthButton
                                type="danger"
                                onClick={() => setShowDeleteModal(true)}
                                disabled={readOnly}
                            >
                                Delete
                            </FullWidthButton>
                        </List.Item>
                        {showDeleteModal &&
                            <DeleteModal
                                name={chart.name}
                                onOk={() => cdc.deleteData(chart.key)}
                                onCancel={() => setShowDeleteModal(false)}
                            />
                        }
                    </List>
                </SiderCollapsePanel>)
            }
        }
        </Subscribe>
    )
}

export default ChartPanel
