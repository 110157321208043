import { cloneDeep, debounce, get, set } from "lodash"
import { injectable, singleton } from "tsyringe"
import { ChartDataProps, ChartKey, ChartType, DataColumn, DataKey } from "../types"
import { emptyChartDataProps } from "../utils/charts/defaults"
import { BaseContainer } from "./BaseContainer"

@singleton()
@injectable()
export class ChartDataContainer extends BaseContainer<ChartDataProps> {
    protected prefix = "Chart"
    protected emptyData = emptyChartDataProps

    public constructor() {
        super()
    }

    public updateDataKey = (key: ChartKey, dataKey: DataKey, callback?: () => void) => {
        const data = this.getData(key)
        if (data) {
            this.resetChart(key)
            const updated = {...data, dataKey}
            this.updateData(updated, callback)
        }
    }

    public resetChart = (key: ChartKey, callback?: () => void) => {
        const data = this.getData(key)
        if (data) {
            const updated = {...data, dataColumns: [], labelColumns: []}
            this.updateData(updated, callback)
        }
    }

    public resetChartOptions = (key: ChartKey, callback?: () => void) => {
        const data = this.getData(key)
        if (data) {
            const options = emptyChartDataProps().options
            const updated = {...data, options}
            this.updateData(updated, callback)
        }
    }

    public getDataKeys = () => this.state.datas.flatMap(d => d.dataKey)

    public updateChartType = (key: ChartKey, type: ChartType, callback?: () => void) => {
        const data = this.getData(key)
        if (data) {
            const updated = {...data, type}
            this.updateData(updated, callback)
        }
    }

    public updateLabelColumns = (key: ChartKey, columns: DataColumn[], callback?: () => void) => {
        const data = this.getData(key)
        if (data) {
            const updated = {...data, labelColumns: columns}
            this.updateData(updated, callback)
        }
    }

    public updateDataColumns = (key: ChartKey, columns: DataColumn[], callback?: () => void) => {
        const data = this.getData(key)
        if (data) {
            const updated = {...data, dataColumns: columns}
            this.updateData(updated, callback)
        }
    }

    private updateSize = (key: ChartKey, size: {width: number, height: number}, callback?: () => void) => {
        const data = this.getData(key)
        if (data) {
            const updated = {...data, width: size.width, height: size.height}
            this.updateData(updated, callback)
        }
    }

    private updateOptionImpl = (key: ChartKey, option: string, value: any, callback?: () => void) => {
        const data = this.getData(key)
        if (data) {
            const options = cloneDeep(data.options)
            // console.log(`updating ${option} -> ${value} : ${get(options, option)}`)
            if (get(options, option) !== value) {
                set(options, option, value)
                const updated = {...data, options}
                // console.log(updated)
                this.updateData(updated, callback)
            }
        }
    }

    // tslint:disable-next-line: member-ordering
    public updateSizeWithDelay = debounce(this.updateSize, 100)

    // tslint:disable-next-line: member-ordering
    public updateOption = debounce(this.updateOptionImpl, 500)
}
