import { Select, Table } from "antd"
import { ColumnProps } from "antd/lib/table"
import React from "react"
import { DataColumn, DataFormatType } from "../../../types"

interface DataFormatTableProps {
    data: DataColumn[],
    handleChangeDataFormatType: (d: DataColumn, f: DataFormatType) => void
    readOnly: boolean
}

const DataFormatTable: React.FunctionComponent<DataFormatTableProps> = (props) => {
    const { handleChangeDataFormatType, data, readOnly } = props
    const columns: Array<ColumnProps<DataColumn>> = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            render: (text: string) => <p>{text}</p>,
        },
        {
            title: "Type",
            key: "dataFormat",
            render: (d: DataColumn) =>
                <Select
                    defaultValue={d.dataFormat}
                    onChange={(format: DataFormatType) => handleChangeDataFormatType(d, format)}
                    style={{ width: "100%" }}
                    disabled={readOnly}
                >
                    <Select.Option value="string">string</Select.Option>
                    <Select.Option value="date">date</Select.Option>
                    <Select.Option value="number">number</Select.Option>
                </Select>,
        },
        {
            title: "Invalid",
            key: "invalid",
            render: (d: DataColumn) =>
                <p>{d.invalid.toString()} ({(d.invalid / (d.raw.length || 1) * 100).toFixed(0)}%)</p>,
        },
    ]

    return(
        <Table style={{width: "fit-content"}} columns={columns} dataSource={data} rowKey="key"/>
    )
}

export default DataFormatTable
