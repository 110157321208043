import React from "react"
import { AppLayout } from "../../../../style"
import ChartContainer from "./ChartContainer"
import ChartTableContainer from "./ChartTableContainer"

const MainContainer: React.FunctionComponent = () => {
    return(
        <AppLayout>
            <ChartContainer/>
            <ChartTableContainer/>
        </AppLayout>

    )
}

export default MainContainer
