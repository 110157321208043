import chartjs from "chart.js"

export enum ChartItError { CHART_NOT_FOUND = "Chart not found" }

export enum DataFormatType {
    date = "date",
    string = "string",
    number = "number",
}

export interface DataFormat {
    dataFormat: DataFormatType
    dateFormat?: string
}

export interface DataProps extends Nameable {
    key: DataKey
    raw: string
    notes: string
    columns: DataColumn[]
    delimiterType: DelimiterType
}

export interface ChartDataProps extends Nameable {
    key: ChartKey
    dataKey?: DataKey
    type?: ChartType
    notes: string
    height: number
    width: number
    labelColumns: DataColumn[]
    dataColumns: DataColumn[]
    options: ChartOptions
}

export interface ChartOptions {
    google: GoogleOptions
    chartjs: ChartJsOptions
}
export interface ChartJsOptions extends chartjs.ChartOptions {
     chartDataSets: chartjs.ChartDataSets[] // get data-set formatting
}
export interface GoogleOptions {
    aggregationType?: string
    animation?: google.visualization.TransitionAnimation
    annotations?: google.visualization.ChartAnnotations
    areaOpacity?: number
    axisTitlesPosition?: google.visualization.ChartAxisTitlesPosition
    backgroundColor?: string | google.visualization.ChartStrokeFill
    bar?: google.visualization.GroupWidth
    bubble?: google.visualization.ChartBubble
    candlestick?: google.visualization.Candlestick
    chartArea?: google.visualization.ChartArea
    colorAxis?: google.visualization.ChartColorAxis
    colors?: string[]
    connectSteps?: boolean
    crosshair?: google.visualization.ChartCrosshair
    curveType?: "none" | "function"
    dataOpacity?: number
    datalessRegionColor: string,
    keepAspectRatio: boolean,
    defaultColor: string,
    displayMode: string,
    enableRegionInteractivity: string,
    markerOpacity: number,
    region: string,
    resolution: string,
    domainAxis?: {type: string}
    enableInteractivity?: boolean
    explorer?: google.visualization.ChartExplorer
    focusTarget?: string
    fontName?: string
    fontSize?: number
    forceIFrame?: boolean
    hAxes?: any
    hAxis?: google.visualization.ChartAxis
    height?: number
    histogram?: google.visualization.HistogramHistogramOptions
    interpolateNulls?: boolean
    interval?: any
    intervals?: {style: string }
    is3D?: boolean
    isStacked?: boolean | "percent" | "relative" | "absolute"
    legend?: google.visualization.ChartLegend | "none"
    lineDashStyle?: number[]
    lineWidth?: number
    min?: number
    orientation?: google.visualization.ChartOrientation
    pieHole?: number
    pieResidueSliceColor?: string
    pieResidueSliceLabel?: string
    pieSliceBorderColor?: string
    pieSliceText?: string
    pieSliceTextStyle?: google.visualization.ChartTextStyle
    pieStartAngle?: number
    pointShape?: google.visualization.ChartPointShape
    pointSize?: number
    pointsVisible?: boolean
    reverseCategories?: boolean
    selectionMode?: google.visualization.ChartSelectionMode
    series?: any
    seriesType?: string
    sizeAxis?: google.visualization.ChartSizeAxis
    slices?: any
    sliceVisibilityThreshold?: number
    sortBubblesBySize?: boolean
    theme?: string
    title?: string
    titlePosition?: string
    titleTextStyle?: google.visualization.ChartTextStyle
    tooltip?: google.visualization.ChartTooltip
    trendlines?: {[key: number]: google.visualization.ChartTrendlineOptions}
    vAxes?: any
    vAxis?: google.visualization.ChartAxis
    width?: number
}
export interface DataAndChartDataProps {
    data: DataProps[]
    chartData: ChartDataProps[]
    path: string
}

export interface DataColumn {
    name: string
    raw: string[]
    dataFormat: DataFormatType
    dateFormat?: string
    invalid: number
    key: string
}

export interface ChartProps {
    chartRef: React.RefObject<HTMLElement>
    labelColumns: DataColumn[]
    dataColumns: DataColumn[]
    chartType: ChartType
    width: number
    height: number
    options: ChartOptions
}

export interface Keyable {
    key: string
}

export interface Nameable {
    name: string
    nameWithType: string
}

export interface ChartMetadata {
    id: string
    group: string
}

export enum DelimiterType { tab = "tab", comma = "comma", pipe = "pipe" }

export type ContainerBase<T> = T & Nameable & {
    key: string
    notes: string
}

export type DataFormattedType = string | number | Date

export enum ChartType {
    area = "area",
    bar = "bar",
    bubble = "bubble",
    candlestick = "candlestick",
    column = "column",
    combo = "combo",
    // "donut",
    // "gauge",
    geo = "geo",
    histogram = "histogram",
    line = "line",
    // "org",
    pie = "pie",
    scatter = "scatter",
    stepped_area = "stepped area",
    // "table",
    // "timeline",
    // "treemap"
    chartjs_line = "chartjs_line",
    chartjs_doughnut = "chartjs_doughnut",
    chartjs_pie = "chartjs_pie",
    chartjs_scatter = "chartjs_scatter",
    chartjs_bar = "chartjs_bar",
    chartjs_horizontal_bar = "chartjs_horizontal_bar",
    chartjs_radar = "chartjs_radar",
    chartjs_polar = "chartjs_polar",
    chartjs_bubble = "chartjs_bubble",
}

export type DataKey = string
export type ChartKey = string
export type ChartGroup = string
