import { container, injectable, singleton } from "tsyringe"
import { Container } from "unstated"
import { ChartKey, ChartType } from "../types"
import { ChartDataContainer } from "./ChartDataContainer"

interface OptionsState {
    chartKey: ChartKey | undefined
    chartType: ChartType | undefined
}

@injectable()
@singleton()
export class ChartOptionsContainer extends Container<OptionsState> {
    public state = {
        chartKey: undefined,
        chartType: undefined,
    }

    private chartDataContainer = container.resolve(ChartDataContainer)

    public constructor() {
        super()
    }

    public getSelectedChart = (): ChartKey | undefined => this.state.chartKey

    public getSelectedChartAndType = (): SelectedChart => {
        const key = this.state.chartKey
        const type = key && this.chartDataContainer.getData(key)?.type
        return { key, type }
    }
    public toggle = (chartKey: ChartKey) => {
        const chartType = this.chartDataContainer.getData(chartKey)?.type
        if (this.state.chartKey === chartKey || !chartType) {
            this.deselectChart()
        } else {
            this.setState({chartKey, chartType})}
        }
    public deselectChart = () => { this.setState({chartKey: undefined, chartType: undefined})}

    public isChartSelected = () => this.state.chartKey !== undefined && this.state.chartType !== undefined
}
