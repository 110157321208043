import { Col, Popover, Select } from "antd"
import React from "react"
import { Subscribe } from "unstated"
import { ChartDataContainer } from "../../../containers/ChartDataContainer"
import { TutorialContainer } from "../../../containers/TutorialContainer"
import { WideRow } from "../../../style"
import { tutorial5 } from "../../../tutorial/tutorialSteps"
import { ChartKey, ChartType } from "../../../types"

interface ChartSelectorProps {
    chartKey: ChartKey
    readOnly: boolean
}

const ChartSelector: React.FunctionComponent<ChartSelectorProps> = (props) => {
    const { chartKey, readOnly } = props

    return(
        // TODO: Make styled components, but not generic!
        // TODO: Enumerate chart types
        <Subscribe to={[ChartDataContainer, TutorialContainer]}>
            {(chartDataContainer: ChartDataContainer, t: TutorialContainer) => {
                const chart = chartDataContainer.getData(chartKey)

                if (chart === undefined) { return null }

                return (
                    <Popover
                        content={tutorial5.content}
                        visible={t.state.curStep === tutorial5.step}
                        placement={tutorial5.placement}
                    >
                        <WideRow>
                            <Col span={8}>Type</Col>
                            <Col span={16}>
                                <Select
                                    placeholder="Select Type"
                                    onChange={(ct: ChartType) => {
                                        t.complete(tutorial5.step)
                                        chartDataContainer.updateChartType(chartKey, ct)}
                                    }
                                    style={{width: "100%", maxWidth: "320px"}}
                                    value={chart.type}
                                    disabled={readOnly}
                                >
                                    <Select.OptGroup label="Google Charts">
                                        <Select.Option key={ChartType.area}>area</Select.Option>
                                        <Select.Option key={ChartType.bar}>bar</Select.Option>
                                        <Select.Option key={ChartType.bubble}>bubble</Select.Option>
                                        <Select.Option key={ChartType.candlestick}>candlestick</Select.Option>
                                        <Select.Option key={ChartType.column}>column</Select.Option>
                                        <Select.Option key={ChartType.combo}>combo</Select.Option>
                                        {/* <Select.Option key="donut">donut</Select.Option>  */}
                                        {/* <Select.Option key="gauge">gauge</Select.Option>  */}
                                        <Select.Option key={ChartType.geo}>geo</Select.Option>
                                        <Select.Option key={ChartType.histogram}>histogram</Select.Option>
                                        <Select.Option key={ChartType.line}>line</Select.Option>
                                        {/* <Select.Option key="org">org</Select.Option> */}
                                        <Select.Option key={ChartType.pie}>pie</Select.Option>
                                        <Select.Option key={ChartType.scatter}>scatter</Select.Option>
                                        <Select.Option key={ChartType.stepped_area}>stepped area</Select.Option>
                                        {/* <Select.Option key="table">table</Select.Option> */}
                                        {/* <Select.Option key="timeline">timeline</Select.Option> */}
                                        {/* <Select.Option key="treemap">treemap</Select.Option> */}
                                    </Select.OptGroup>
                                    <Select.OptGroup label="Chart.js">
                                        <Select.Option key={ChartType.chartjs_bar}>bar</Select.Option>
                                        <Select.Option key={ChartType.chartjs_bubble}>bubble</Select.Option>
                                        <Select.Option key={ChartType.chartjs_doughnut}>doughnut</Select.Option>
                                        <Select.Option key={ChartType.chartjs_horizontal_bar}>horizontal bar</Select.Option>
                                        <Select.Option key={ChartType.chartjs_line}>line</Select.Option>
                                        <Select.Option key={ChartType.chartjs_pie}>pie</Select.Option>
                                        <Select.Option key={ChartType.chartjs_polar}>polar</Select.Option>
                                        <Select.Option key={ChartType.chartjs_radar}>radar</Select.Option>
                                        <Select.Option key={ChartType.chartjs_scatter}>scatter</Select.Option>
                                    </Select.OptGroup>
                                </Select>
                            </Col>
                        </WideRow>
                    </Popover>
                    )
            }
        }
        </Subscribe>
)}

export default ChartSelector
