import { Collapse, Select } from "antd"
import React, { useState } from "react"
import { container } from "tsyringe"
import { ChartDataContainer } from "../../../../../../containers/ChartDataContainer"
import { OptionsCollapsePanel } from "../../../../../../style"
import { ChartKey } from "../../../../../../types"
import { buildOption as b } from "../../utils"
import { DatasetSelect } from "./style"

interface RadarDatasetsProps {
    chartKey: ChartKey
}

const RadarDatasets: React.FunctionComponent<RadarDatasetsProps> = (props) => {
    const { chartKey } = props
    const header = "Datasets"
    const prefix = header.toLowerCase()

    const [selected, setSelected] = useState(0)

    const chartContainer = container.resolve(ChartDataContainer)
    const chartData = chartContainer.getData(chartKey)
    const columns = chartData?.dataColumns || []

    const borderDashes = [
        { label: "solid", value: [] },
        { label: "6-6", value: [6, 6] },
        { label: "12-12", value: [12, 12] },
        { label: "5-1", value: [5, 1] },
        { label: "15-5", value: [15, 5] },
        { label: "3-5-8-5", value: [3, 5, 8, 5] },
    ]

    return(
        <Collapse bordered={false} style={{width: "100%"}} key={`${chartKey}${prefix}`}>
            <OptionsCollapsePanel header={header} key={"header"}>
                <DatasetSelect onSelect={v => typeof v === "number" && setSelected(v)} value={selected}>
                    {columns.map((c, idx) =>
                        <Select.Option value={idx} key={c.key}>
                            {c.name}
                        </Select.Option>,
                    )}
                </DatasetSelect>
                {b(chartKey, "Border Color", `chartjs.chartDataSets[${selected}].borderColor`, "color")}
                {b(chartKey, "Background Color", `chartjs.chartDataSets[${selected}].backgroundColor`, "color")}
                {b(
                    chartKey,
                    "Border Dash",
                    `chartjs.chartDataSets[${selected}].borderDash`,
                    "enum",
                    borderDashes,
                )}
                {b(chartKey, "Border Width", `chartjs.chartDataSets[${selected}].borderWidth`, "number")}
                {b(chartKey, "Label", `chartjs.chartDataSets[${selected}].label`, "string")}
                {b(chartKey, "Pt Border Color", `chartjs.chartDataSets[${selected}].pointBorderColor`, "color")}
                {b(chartKey, "Pt Background Color", `chartjs.chartDataSets[${selected}].pointBackgroundColor`, "color")}
            </OptionsCollapsePanel>
        </Collapse>
    )
}

export default RadarDatasets
