import { Collapse } from "antd"
import React from "react"
import { ChartDefaults } from "../../../../../../config"
import { OptionsCollapsePanel } from "../../../../../../style"
import { ChartKey } from "../../../../../../types"
import { buildOption as b } from "../../utils"

interface GraphAxisProps {
    chartKey: ChartKey
    axis: "h" | "v"
}

const GraphAxis: React.FunctionComponent<GraphAxisProps> = (props) => {
    const { chartKey, axis } = props
    const prefix = axis === "h" ? "hAxis" : "vAxis"
    const header = axis === "h" ? "Horizontal Axis" : "Vertical Axis"
    return(
        <Collapse bordered={false} style={{width: "100%"}}>
            <OptionsCollapsePanel header={header} key={"header"}>
                {b(chartKey, "Title", `google.${prefix}.title`, "string")}
                {b(chartKey, "Baseline", `google.${prefix}.baseline`, "number")}
                {b(chartKey, "Baseline Color", `google.${prefix}.baselineColor`, "color")}
                {b(chartKey, "Direction", `google.${prefix}.direction`, "enum", ["1", "-1"])}
                {b(chartKey, "Format", `google.${prefix}.format`, "enum", formatOptions)}
                {b(chartKey, "Gridlines Color",
                    `google.${prefix}.gridlines.color`, "color")}
                {b(chartKey, "Gridlines Count",
                    `google.${prefix}.gridlines.count`, "number")}
                {b(chartKey, "Log Scale", `google.${prefix}.logScale`, "boolean")}
                {b(chartKey, "Text Position", `google.${prefix}.textPosition`, "enum", ["in", "out", "none"])}
            </OptionsCollapsePanel>
        </Collapse>
    )
}

const formatOptions = ["none", "decimal", "scientific", "currency", "percent", "short", "long", "auto"]

export default GraphAxis
