import React, { useCallback, useEffect, useState } from "react"
import { IconRoulette } from "./style"

const ChartRoulette: React.FunctionComponent = () => {
    const items = ["area-chart", "pie-chart", "bar-chart", "dot-chart", "line-chart", "box-plot", "sliders", "stock"]

    const [value, setValue] = useState(items[0])
    const [, setNumItem] = useState(0)

    const update = useCallback(() => {
        setTimeout(() => {
            setNumItem(prior => {
                setValue(items[prior])
                return (prior + 1) % items.length
        })}, 500)
    }, [items])

    
    // TODO: cancel timeout in cleanup
    useEffect(() => {
        update()
    }, [update])

    return(<IconRoulette type={value}/>)
}

export default ChartRoulette
