
import React from "react"
import { ChartKey } from "../../../../../types"
import { buildOption as b } from "../utils"
import Animation from "./components/Animation"
import BarDatasets from "./components/BarDatasets"
import Hover from "./components/Hover"
import Legend from "./components/Legend"
import LineDatasets from "./components/LineDatasets"
import PieDatasets from "./components/PieDatasets"
import RadarDatasets from "./components/RadarDatasets"
import Title from "./components/Title"
import Tooltips from "./components/Tooltips"

export const legend = (chartKey: ChartKey) =>
    <Legend chartKey={chartKey} key={"js-legend"}/>

export const title = (chartKey: ChartKey) =>
    <Title chartKey={chartKey} key={"js-title"}/>

export const tooltip = (chartKey: ChartKey) =>
    <Tooltips chartKey={chartKey} key={"js-tooltip"}/>

export const hover = (chartKey: ChartKey) =>
    <Hover chartKey={chartKey} key={"js-hover"}/>

export  const animation = (chartKey: ChartKey) =>
    <Animation chartKey={chartKey} key={"js-animation"}/>

export const lineDatasets = (chartKey: ChartKey) =>
    <LineDatasets chartKey={chartKey} key={"js-datasets"}/>

export const radarDatasets = (chartKey: ChartKey) =>
    <RadarDatasets chartKey={chartKey} key={"js-datasets"}/>

export const pieDatasets = (chartKey: ChartKey) =>
    <PieDatasets chartKey={chartKey} key={"js-datasets"}/>

export const barDatasets = (chartKey: ChartKey) =>
    <BarDatasets chartKey={chartKey} key={"js-datasets"}/>

export const responsive = (chartKey: ChartKey) =>
    b(chartKey, "Responsive", "chartjs.responsive", "boolean")

export  const showLines = (chartKey: ChartKey) =>
    b(chartKey, "Show Lines", "chartjs.showLines", "boolean")

export  const spanGaps = (chartKey: ChartKey) =>
    b(chartKey, "Span Gaps", "chartjs.spanGaps", "boolean")

export  const cutoutPercentage = (chartKey: ChartKey) =>
    b(chartKey, "Cutout Percentage", "chartjs.cutoutPercentage", "number")

export  const circumference = (chartKey: ChartKey) =>
    b(chartKey, "Circumference", "chartjs.circumference", "number")
