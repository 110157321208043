import { Analytics, Storage } from "aws-amplify"
import { AppConfigs } from "../config"
import { ChartDataProps, DataProps } from "../types"

const createSavePath = (group: string, chartId: string) => `${AppConfigs.chartsS3dir}/${group}/${chartId}`

export const createShareUrl = (group: string, chartId: string) =>
    `${AppConfigs.basePath}/${createSavePath(group, chartId)}`

export const save = async (group: string, chartId: string, data: DataProps[], chartData: ChartDataProps[]) => {
    const json = JSON.stringify({data, chartData})
    const path = createSavePath(group, chartId)
    const out = Storage.put(`${path}.json`, json)

    return out.then((o: any) => {
        const key = o.key
        if (typeof(key) === "string") {
            const savedPath = key.slice(0, key.length - 5) // remove .json
            const url = `${AppConfigs.basePath}/${savedPath}`
            return url
        }
    })
}

export const share = async (chartId: string, data: DataProps[], chartData: ChartDataProps[]) => {
    Analytics.record({
        name: "Chart Share",
        attributes: {chartId},
    })
    return save(AppConfigs.publicGroup, chartId, data, chartData)
}
