import { Collapse } from "antd"
import React from "react"
import { ChartDefaults } from "../../../../../../config"
import { OptionsCollapsePanel } from "../../../../../../style"
import { ChartKey } from "../../../../../../types"
import { buildOption as b } from "../../utils"

interface AnimationProps {
    chartKey: ChartKey
}

const Animation: React.FunctionComponent<AnimationProps> = (props) => {
    const { chartKey } = props
    const header = "Animation"
    const prefix = header.toLowerCase()

    return(
        <Collapse bordered={false} style={{width: "100%"}} key={`${chartKey}${prefix}`}>
            <OptionsCollapsePanel header={header} key={"header"}>
                {b(
                    chartKey,
                    "Duration",
                    "chartjs.animation.duration",
                    "number",
                )}
                {b(
                    chartKey,
                    "Easing",
                    "chartjs.animation.easing",
                    "enum",
                    easing,
                )}
                {b(
                    chartKey,
                    "Animate Rotate",
                    "chartjs.animation.animateRotate",
                    "boolean",
                )}
                {b(
                    chartKey,
                    "Animate Scale",
                    "chartjs.animation.animateScale",
                    "boolean",
                )}
            </OptionsCollapsePanel>
        </Collapse>
    )
}

export default Animation

const easing = [
    "linear",
    "easeInQuad",
    "easeOutQuad",
    "easeInOutQuad",
    "easeInCubic",
    "easeOutCubic",
    "easeInOutCubic",
    "easeInQuart",
    "easeOutQuart",
    "easeInOutQuart",
    "easeInQuint",
    "easeOutQuint",
    "easeInOutQuint",
    "easeInSine",
    "easeOutSine",
    "easeInOutSine",
    "easeInExpo",
    "easeOutExpo",
    "easeInOutExpo",
    "easeInCirc",
    "easeOutCirc",
    "easeInOutCirc",
    "easeInElastic",
    "easeOutElastic",
    "easeInOutElastic",
    "easeInBack",
    "easeOutBack",
    "easeInOutBack",
    "easeInBounce",
    "easeOutBounce",
    "easeInOutBounce",
]
