import { Typography } from "antd"
import React, { useEffect } from "react"
import { AppConfigs } from "../app/config"
import createHtml from "../images/create-html.png"
import saveHtml from "../images/save-html.png"
import shareHtml from "../images/share-html.png"
import LandingFeaturesRow from "./LandingFeaturesRow"

const ApiDemo: React.FunctionComponent = () => {

    useEffect(() => {
        const script = document.createElement("script")

        script.src = "https://api.chartit.io/chart.js"
        script.async = true
        script.setAttribute("path", "explore/crypto/ZWk3-m6E")
        script.setAttribute("attachId", "myChartId")

        document.body.appendChild(script)

        return () => {
          document.body.removeChild(script)
        }
      }, [])

    const features = [
        {
            title: "Step 1 - Create Chart",
            text: <Typography.Paragraph>
                Any chart you create using Chart It can
                 be injected into html. First create a chart. Once the chart is complete, select the
                 html icon.
            </Typography.Paragraph>,
            rightSide: <img src={createHtml} alt="Create html" width="65%"/>,
        },
        {
            title: "Step 2 - Save Chart",
            text: <Typography.Paragraph>
                Before you can embed a chart into html, you have to save it. Saving
                 the chart will create a public url with the chart data (chart url will
                 not be posted anywhere).
            </Typography.Paragraph>,
            rightSide: <img src={saveHtml} alt="Save html" width="65%"/>,
        },
        {
            title: "Step 3 - Share Chart",
            text: <Typography.Paragraph>
                After chart has been saved, just place the following script tag presented
                 directly into your html &lt;head&gt; section. To add the chart, just include a &lt;div&gt;
                 element with the id specified in the script (e.g. myChartId)
            </Typography.Paragraph>,
            rightSide: <img src={shareHtml} alt="Share html" width="65%"/>,
        },
        {
            title: "Step 4 - Review Chart",
            text: <Typography.Paragraph>
                Your chart will appear in place of the &lt;div&gt; element with the id
                 specified in the script. The chart will be interactive and contain a link to
                 the public url. You can set height and width and all fields can be either strings
                 or arrays. See more examples <a
                 rel="noopener noreferrer" target="_blank" href={AppConfigs.codepenPath}>here</a>.
            </Typography.Paragraph>,
            rightSide: <div id="myChartId"/>,
        },
    ]
    return(
        <React.Fragment>
            <Typography.Title level={2} id="api">
                Embed charts into existing HTML
            </Typography.Title>
            <br/>
            {features.map(({title, text, rightSide}) =>
                <LandingFeaturesRow
                    key={title}
                    title={title}
                    text={text}
                    rightSide={rightSide}
                />,
            )}
        </React.Fragment>
    )
}

export default ApiDemo
