import { PositionType } from "chart.js"
import colors from "../colors"
import { DelimiterType } from "./types"

const chartjsDefaultColors = [
    "#36A2EB",
    "#FF6384",
    "#FF9F40",
    "#FFCD56",
    "#4BC0C0",
    "#9966FF",
    "#C9CBCF",
    "#3366CC",
    "#DC3912",
    "#FF9900",
    "#109618",
    "#990099",
    "#3B3EAC",
    "#0099C6",
    "#DD4477",
    "#66AA00",
    "#B82E2E",
    "#316395",
    "#994499",
    "#22AA99",
    "#AAAA11",
    "#6633CC",
    "#E67300",
    "#8B0707",
    "#329262",
    "#5574A6",
    "#3B3EAC",
]

export const ChartDefaults = {
    delimiterType: DelimiterType.tab,
    height: 300,
    width: 400,
    chartjs: {
        responsive: true,
        showLines: true,
        spanGaps: true,
        cutoutPercentage: 50,
        circumference: 2 * Math.PI,
        title: {
            display: true,
            text: "",
            fontSize: 12,
            fontColor: "#666",
            position: "top" as "top",
            fullWidth: true,
            style: "bold",
            padding: 10,
            fontFamily: "Helvetica",
            fontStyle: "normal",
        },
        legend: {
            align: "center" as "center",
            position: "top" as PositionType,
            display: true,
            fullWidth: true,
            reverse: false,
        },
        tooltips: {
            enabled: true,
            mode: "nearest" as "nearest",
            intersect: true,
            backgroundColor: colors.black,
            titleAlign: "left" as "left",
            titleFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
            titleFontSize: 12,
            titleFontStyle: "bold" as "bold",
            titleFontColor: colors.white,
            titleSpacing: 2,
            titleMarginBottom: 6,
            bodyAlign: "left" as "left",
            bodyFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
            bodyFontSize: 12,
            bodyFontStyle: "normal" as "normal",
            bodyFontColor: colors.white,
            bodySpacing: 2,
            footerAlign: "left" as "left",
            footerFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
            footerFontSize: 12,
            footerFontStyle: "bold" as "bold",
            footerFontColor: colors.white,
            footerSpacing: 2,
            footerMarginTop: 6,
            xPadding: 6,
            yPadding: 6,
            caretPadding: 2,
            caretSize: 5,
            cornerRadius: 6,
            multiKeyBackground: colors.white,
            position: "average" as "average",
            displayColors: true,
            borderColor: colors.white,
            borderWidth: 0,
        },
        hover: {
            mode: "nearest" as "nearest",
            intersect: true,
            axis: "x" as "x",
            animationDuration: 400,
        },
        animation: {
            duration: 1000,
            easing: "easeOutQuart" as "easeOutQuart",
            animateRotate: true,
            animateScale: false,
        },
        scales: {
            xAxes: [{
                offset: true,
                stacked: false,
                display: true,
                gridLines: {
                    display: true,
                },
            }],
            yAxes: [{
                id: "left",
                type: "linear",
                position: "left",
                display: true,
                gridLines: {
                    display: true,
                },
                scaleLabel: {
                    display: false,
                    labelString: "",
                },
              }, {
                id: "right",
                type: "linear",
                position: "right",
                display: false,
                gridLines: {
                    display: true,
                },
                scaleLabel: {
                    display: false,
                    labelString: "",
                },
              },
            ],
        },
        maintainAspectRatio: false, // allows resizing where aspect ratio changes, not settable
        chartDataSets: chartjsDefaultColors.map(c => ({
            backgroundColor: `${c}33`, // 0.2 alpha
            borderColor: c,
            borderDash: [],
            pointBorderColor: c,
            pointBackgroundColor: colors.white,
            borderWidth: 3,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: c,
            pointHoverBorderColor: colors.grey,
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            yAxisID: "left",
        })),
    },
    google: {
        title: "",
        theme: "none",
        titlePosition: "out",
        fontSize: 12,
        lineWidth: 2,
        pointSize: 1,
        backgroundColor: "white",
        aggregationType: "auto",
        axisTitlesPosition: "out" as google.visualization.ChartAxisTitlesPosition,
        dataOpacity: 1,
        fontName: "Arial",
        axisTitle: "",
        baseline: 1,
        baselineColor: "black",
        direction: 1,
        datalessRegionColor: "#F5F5F5",
        keepAspectRatio: true,
        defaultColor: "#267114",
        displayMode: "auto",
        enableRegionInteractivity: "automatic",
        markerOpacity: 1.0,
        region: "world",
        resolution: "countries",
        format: "auto",
        gridlinesColor: "black",
        gridlinesCount: 5,
        logScale: "false",
        textPosition: "out",
        curveType: "none" as "none",
        focusTarget: "datum",
        orientation: "horizontal" as google.visualization.ChartOrientation,
        isStacked: false,
        series: [
            {
                labelInLegend: undefined,
            },
            {
                labelInLegend: undefined,
            },
        ],
        chartLegend: {
            alignment: "automatic",
            maxLines: 1,
            position: "right",
        },
    },
}

export const AppConfigs = {
    basePath: "https://www.chartit.io",
    basePathDev: "localhost:3000",
    chartsPath: "/charts",
    tutorialPath: "/app/tutorial",
    appPath: "/app",
    apiFile: "https://api.chartit.io/chart.js",
    apiPath: "/api",
    chartNotFoundPath: "/chart-not-found",
    explorePath: "/explore",
    publicGroup: "public",
    exploreGroup: "explore",
    chartsS3dir: "charts",
    supportContact: "support@chartit.io",
    waitlistContact: "waitlist@chartit.io",
    codepenPath: "https://jsfiddle.net/breeko/wmxdcnyb/3/",
}
