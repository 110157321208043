import { Storage } from "aws-amplify"
import { AppConfigs } from "../config"
import { ChartItError, ChartMetadata, DataAndChartDataProps } from "../types"

const getAppPath = (metadata: ChartMetadata) =>
    `${AppConfigs.basePath}${AppConfigs.chartsPath}/${metadata.group}/${metadata.id}`

const getS3AppPath = (metadata: ChartMetadata) =>
    `${AppConfigs.chartsS3dir}/${metadata.group}/${metadata.id}.json`

export const getSavedChart = async (metadata: ChartMetadata) => {
    const s3Path = getS3AppPath(metadata)
    return Storage.get(s3Path, { download: true })
        .then((response: any) => {
            // const parsed = parseSavedData(response)
            const body = response.Body
            if (!body) {
                throw new Error(ChartItError.CHART_NOT_FOUND)
            }
            const parsedResponse = JSON.parse(body.toString())
            const path = getAppPath(metadata)
            const data = {...parsedResponse, path} as DataAndChartDataProps
            return(data)
        })

}