import { Popover, Tooltip } from "antd"
import React, { useState } from "react"
import { container } from "tsyringe"
import { Subscribe } from "unstated"
import { ChartDataContainer } from "../../containers/ChartDataContainer"
import { ChartOptionsContainer } from "../../containers/ChartOptionsContainer"
import { DataContainer } from "../../containers/DataContainer"
import { MetaContainer } from "../../containers/MetaContainer"
import { TutorialContainer } from "../../containers/TutorialContainer"
import { tutorial1, tutorial11, tutorial3, tutorial9, tutorialFinal } from "../../tutorial/tutorialSteps"
import { createShareUrl, save, share } from "../../utils/shareUtils"
import SaveModal from "../modals/SaveModal"
import ShareModal from "../modals/ShareModal"
import ShareResultModal from "../modals/ShareResultModal"
import TutorialCompleteModal from "../modals/TutorialCompleteModal"
import { AppToolbar, ToolbarDivider, ToolbarIcon, ToolbarIconDisabled } from "./style"

const MainToolbar: React.FunctionComponent = () => {

    const [showShareModal, setShowShareModal] = useState(false)
    const [showSaveModal, setShowSaveModal] = useState(false)
    const [showShareResultModal, setShowShareResultModal] = useState(false)
    const [shareUrl, setShareUrl] = useState<string|undefined>()

    const dataContainer = container.resolve(DataContainer)
    const chartDataContainer = container.resolve(ChartDataContainer)
    const metaContainer = container.resolve(MetaContainer)

    const toolbarIconWrapper = (
        type: string, onClick: () => void, enabled: boolean, theme?: string,
        ) => {
        if (enabled) {
            return <ToolbarIcon type={type} onClick={onClick} theme={theme}/>
        } else {
            return <ToolbarIconDisabled type={type} theme={theme}/> }
    }

    return(
        <Subscribe to={[ChartOptionsContainer, TutorialContainer, MetaContainer]}>
            {(cdc: ChartOptionsContainer, t: TutorialContainer, mc: MetaContainer) => {
            const optionsSelected = cdc.isChartSelected()

            return (
                <AppToolbar>
                    <Popover
                        visible={t.state.curStep === tutorial9.step}
                        content={tutorial9.content}
                        placement={tutorial9.placement}
                    >
                        <Tooltip title="Close Settings" placement="bottomLeft">
                            {toolbarIconWrapper("close",
                                () => {
                                    t.complete(tutorial9.step)
                                    cdc.deselectChart()
                                }, optionsSelected)}
                        </Tooltip>
                    </Popover>
                    <Tooltip title="Pin Side Panel" placement="bottomLeft">
                        {toolbarIconWrapper(
                            "pushpin",
                            () => { mc.toggleSticky() },
                            !optionsSelected,
                            mc.state.sticky ? "filled" : undefined)
                        }
                    </Tooltip>
                    <ToolbarDivider>|</ToolbarDivider>
                    <Popover
                        visible={t.state.curStep === tutorial1.step}
                        content={tutorial1.content}
                        placement={tutorial1.placement}
                    >
                        <Tooltip title="Add Data" placement="bottomLeft">
                            {toolbarIconWrapper("inbox",
                                () => {
                                    t.complete(tutorial1.step)
                                    dataContainer.addEmptyData()
                                }, !optionsSelected &&
                                        (mc.state.readOnly === false))}
                        </Tooltip>
                    </Popover>
                    <Popover
                        visible={t.state.curStep === tutorial3.step}
                        content={tutorial3.content}
                        placement={tutorial3.placement}
                    >
                        <Tooltip title="Add Chart" placement="bottomLeft">
                            {toolbarIconWrapper(
                                "line-chart",
                                () => {
                                    t.complete(tutorial3.step)
                                    chartDataContainer.addEmptyData()
                                },
                                !optionsSelected && mc.state.readOnly === false)}
                        </Tooltip>
                    </Popover>
                    <ToolbarDivider>|</ToolbarDivider>
                    <Popover
                        visible={t.state.curStep === tutorial11.step}
                        content={tutorial11.content}
                        placement={tutorial11.placement}
                        >
                        <Tooltip title="Share Chart" placement="bottomLeft">
                            {toolbarIconWrapper("share-alt",
                            () => {
                                t.complete(tutorial11.step)
                                setShowShareModal(true)},
                                !optionsSelected)}
                        </Tooltip>
                    </Popover>
                    <Tooltip title="Save Chart" placement="bottomLeft">
                        {
                            toolbarIconWrapper(
                                "save",
                                () => setShowSaveModal(true),
                                process.env.NODE_ENV === "development")
                        }
                    </Tooltip>
                    {showShareModal &&
                        <ShareModal
                            readOnly={metaContainer.state.readOnly}
                            placeholderUrl={createShareUrl(metaContainer.state.group, metaContainer.state.chartId)}
                            saveChart={() =>
                                share(metaContainer.state.chartId, dataContainer.getAll(),chartDataContainer.getAll())}
                            onCancel={() => setShowShareModal(false)}
                        />
                    }
                    {showSaveModal &&
                        <SaveModal
                            onOk={(group: string, chartId: string) => {
                                setShowSaveModal(false)
                                save(group, chartId, dataContainer.getAll(), chartDataContainer.getAll())
                                    .then(url => { if (url) { setShareUrl(url)}})
                                    .finally(() => setShowShareResultModal(true))
                            }}
                            chartId={metaContainer.state.chartId}
                            group={metaContainer.state.group}
                            onCancel={() => setShowSaveModal(false)}
                        />
                    }
                    {showShareResultModal &&
                        <ShareResultModal
                            url={shareUrl}
                            onOk={() => {
                                setShowShareResultModal(false)
                                setShareUrl(undefined)}
                            }
                        />
                    }
                    { (showShareModal || (t.state.curStep !== tutorialFinal.step)) ? null : <TutorialCompleteModal/> }
                </AppToolbar>
            )
        }
    }
        </Subscribe>
    )
}

export default MainToolbar
