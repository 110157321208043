import { Modal, Typography } from "antd"
import React from "react"

const { Text } = Typography

interface DeleteModalProps {
    name: string | undefined
    onOk: () => void
    onCancel: () => void
}

const DeleteModal: React.FunctionComponent<DeleteModalProps> = (props) => {
    const {name, onOk, onCancel} = props

    return(
        <Modal
            title="Confirm Delete"
            visible={true}
            okType="danger"
            okText="Delete"
            onOk={onOk}
            onCancel={onCancel}
        >
            <Text>Are you sure you want to delete{ name ? ` ${name}` : ""}?
             This cannot be undone</Text>
        </Modal>
    )
}

export default DeleteModal
