import React from "react"
import { SiderCollapse, SiderCollapsePanelBase } from "../../../style"
import { DataColumn, DataFormatType, DataProps } from "../../../types"
import DataFormatTable from "./DataFormatTable"

interface DataColumnValidatorProps {
    data: DataProps
    handleChangeDataFormatType: (c: DataColumn, f: DataFormatType) => void
    readOnly: boolean
}

const DataColumnValidator: React.FunctionComponent<DataColumnValidatorProps> = (props) => {
    const { data, handleChangeDataFormatType, readOnly } = props
    return(
        <SiderCollapse bordered={false}>
            <SiderCollapsePanelBase
                header="Column Validations"
                key="column-validations"
                style={{
                    minWidth: "100%",
                    maxWidth: "320px",
                    overflow: "auto",
                }}
            >
                <DataFormatTable
                    data={data.columns}
                    readOnly={readOnly}
                    handleChangeDataFormatType={handleChangeDataFormatType}
                />
            </SiderCollapsePanelBase>
        </SiderCollapse>
    )
}

export default DataColumnValidator
