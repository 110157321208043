import { Button, Icon, Row, Typography } from "antd"
import styled from "styled-components"

export const IconRoulette = styled(Icon)`
    font-size: 40px;
    margin: 5px;
`

// Why can't I add color: color.blue here??
export const MainMessage = styled(Typography.Title)`
    text-align: center;
    margin: 10px;
`

export const LandingButton = styled(Button)`
    width: 150px;
`

export const LandingVideo = styled.video`
    max-width: 90%;
    max-height: 50vh;
    box-shadow: -5px 5px gainsboro;
    margin: 20px;
    border-radius: 12px;
    border-color: black;
    border-width: 3px;
    border-style: solid;
`

export const MarginRow = styled(Row)`
    margin: 10px;
`
