import { Col, Form, Input, Typography } from "antd"
import styled from "styled-components"

const { Title } = Typography

export const ColorInput = styled(Input)`
    color: white;
    text-shadow: 0.07em 0 black, 0 0.07em black, -0.07em 0 black, 0 -0.07em black;
`

export const OptionValueCol = styled(Col)`
    display: flex !important;
    flex-direction: column;
    align-items: center;
`

export const OptionHeader = styled(Title)`
    text-align: center;
`
export const OptionItem = styled(Form.Item)`
    margin-bottom: 0px !important;
`

export const OptionLabel = styled(Typography.Text)`
    overflow: hidden;
    white-space: nowrap;
`

