import { Collapse } from "antd"
import React from "react"
import { ChartDefaults } from "../../../../../../config"
import { OptionsCollapsePanel } from "../../../../../../style"
import { ChartKey } from "../../../../../../types"
import { buildOption as b } from "../../utils"

interface TooltipsProps {
    chartKey: ChartKey
}

const Tooltips: React.FunctionComponent<TooltipsProps> = (props) => {
    const { chartKey } = props
    const header = "Tooltips"
    const prefix = "tooltips"

    return(
        <Collapse bordered={false} style={{width: "100%"}} key={`${chartKey}${prefix}`}>
            <OptionsCollapsePanel header={header} key="header">
                {b(
                    chartKey,
                    "Enabled",
                    "chartjs.tooltips.enabled",
                    "boolean",
                    )
                }
                {b(
                    chartKey,
                    "Mode",
                    "chartjs.tooltips.mode",
                    "enum",
                    ["average", "nearest"],
                )}
                {b(
                    chartKey,
                    "Intersect",
                    "chartjs.tooltips.intersect",
                    "boolean",
                    )
                }
                {b(
                    chartKey,
                    "Background Color",
                    "chartjs.tooltips.backgroundColor",
                    "color",
                    )
                }
                {b(
                    chartKey,
                    "X Padding",
                    "chartjs.tooltips.xPadding",
                    "number",
                    )
                }
                {b(
                    chartKey,
                    "Y Padding",
                    "chartjs.tooltips.yPadding",
                    "number",
                    )
                }
                {b(
                    chartKey,
                    "Caret Padding",
                    "chartjs.tooltips.caretPadding",
                    "number",
                    )
                }
                {b(
                    chartKey,
                    "Caret Size",
                    "chartjs.tooltips.caretSize",
                    "number",
                    )
                }
                {b(
                    chartKey,
                    "Corner Radius",
                    "chartjs.tooltips.cornerRadius",
                    "number",
                    )
                }
                {b(
                    chartKey,
                    "Multi-Key Background",
                    "chartjs.tooltips.multiKeyBackground",
                    "color",
                    )
                }
                {b(
                    chartKey,
                    "Position",
                    "chartjs.tooltips.position",
                    "enum",
                    ["average", "nearest"],
                    )
                }
                {b(
                    chartKey,
                    "Display Colors",
                    "chartjs.tooltips.displayColors",
                    "boolean",
                    )
                }
                {b(
                    chartKey,
                    "Border Color",
                    "chartjs.tooltips.borderColor",
                    "color",
                    )
                }
                {b(
                    chartKey,
                    "Border Width",
                    "chartjs.tooltips.borderWidth",
                    "number",
                    )
                }
                <Collapse bordered={false} style={{width: "100%"}} key={`${chartKey}title`}>
                    <OptionsCollapsePanel header="Title" key={"title"}>
                    {b(
                        chartKey,
                        "Align",
                        "chartjs.tooltips.titleAlign",
                        "enum",
                        ["left", "center", "right"]
                        )
                    }
                                        {b(
                        chartKey,
                        "Font Family",
                        "chartjs.tooltips.titleFontFamily",
                        "string",
                        )
                    }
                    {b(
                        chartKey,
                        "Font Size",
                        "chartjs.tooltips.titleFontSize",
                        "number",
                        )
                    }
                    {b(
                        chartKey,
                        "Font Style",
                        "chartjs.tooltips.titleFontStyle",
                        "string",
                        )
                    }
                    {b(
                        chartKey,
                        "Font Color",
                        "chartjs.tooltips.titleFontColor",
                        "color",
                        )
                    }
                    {b(
                        chartKey,
                        "Spacing",
                        "chartjs.tooltips.titleSpacing",
                        "number",
                        )
                    }
                    {b(
                        chartKey,
                        "Margin Bottom",
                        "chartjs.tooltips.titleMarginBottom",
                        "number",
                        )
                    }
                    </OptionsCollapsePanel>
                </Collapse>
                <Collapse bordered={false} style={{width: "100%"}} key={`${chartKey}body`}>
                    <OptionsCollapsePanel header="Body" key="body">
                    {b(
                        chartKey,
                        "Align",
                        "chartjs.tooltips.bodyAlign",
                        "enum",
                        ["left", "center", "right"]
                        )
                    }
                    {b(
                        chartKey,
                        "Font Family",
                        "chartjs.tooltips.bodyFontFamily",
                        "string",
                        )
                    }
                    {b(
                        chartKey,
                        "Font Size",
                        "chartjs.tooltips.bodyFontSize",
                        "number",
                        )
                    }
                    {b(
                        chartKey,
                        "Font Style",
                        "chartjs.tooltips.bodyFontStyle",
                        "string",
                        )
                    }
                    {b(
                        chartKey,
                        "Font Color",
                        "chartjs.tooltips.bodyFontColor",
                        "color",
                        )
                    }
                    {b(
                        chartKey,
                        "Spacing",
                        "chartjs.tooltips.bodySpacing",
                        "number",
                        )
                    }
                    </OptionsCollapsePanel>
                </Collapse>
                <Collapse bordered={false} style={{width: "100%"}} key={`${chartKey}footer`}>
                    <OptionsCollapsePanel header="Footer" key="footer">
                    {b(
                        chartKey,
                        "Align",
                        "chartjs.tooltips.footerAlign",
                        "enum",
                        ["left", "center", "right"],
                        )
                    }
                    {b(
                        chartKey,
                        "Font Family",
                        "chartjs.tooltips.footerFontFamily",
                        "string",
                        )
                    }
                    {b(
                        chartKey,
                        "Font Size",
                        "chartjs.tooltips.footerFontSize",
                        "number",
                        )
                    }
                    {b(
                        chartKey,
                        "Font Style",
                        "chartjs.tooltips.footerFontStyle",
                        "string",
                        )
                    }
                    {b(
                        chartKey,
                        "Font Color",
                        "chartjs.tooltips.footerFontColor",
                        "color",
                        )
                    }
                    {b(
                        chartKey,
                        "Spacing",
                        "chartjs.tooltips.footerSpacing",
                        "number",
                        )
                    }
                    {b(
                        chartKey,
                        "Margin Top",
                        "chartjs.tooltips.footerMarginTop",
                        "number",
                        )
                    }
                    </OptionsCollapsePanel>
                </Collapse>
            </OptionsCollapsePanel>
        </Collapse>
    )
}

// tooltip: {
//     footerAlign: "left",
//     footerFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
//     footerFontSize: 12,
//     footerFontStyle: "bold",
//     footerFontColor: colors.white,
//     footerSpacing: 2,
//     footerMarginTop: 6,
//     xPadding: 6,
//     yPadding: 6,
//     caretPadding: 2,
//     caretSize: 5,
//     cornerRadius: 6,
//     multiKeyBackground: colors.white,
//     position: "average",
//     displayColors: true,
//     borderColor: colors.white,
//     borderWidth: 0,
// },
export default Tooltips
