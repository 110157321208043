import { Col, Row, Typography } from "antd"
import React from "react"
import { Link } from "react-router-dom"
import { AppConfigs } from "../../app/config"
import colors from "../../colors"
import { FeatureDiv } from "../style"
import ChartRoulette from "./ChartRoulette"
import LandingSplashChart from "./LandingSplashChart"
import { LandingButton, MainMessage, MarginRow } from "./style"

const { Title } = Typography

const LandingSplash: React.FunctionComponent = () => {

    return(
        <React.Fragment>
            <Row style={{width: "100%", display: "flex", alignItems: "center"}}>
                <Col
                    style={{alignItems: "center", display: "flex", flexDirection: "column"}}
                    xs={24} sm={24} md={24} lg={9} xl={9}>
                    <FeatureDiv>
                        <MarginRow>
                            <MainMessage style={{color: colors.blue}}>
                                Create and share beautiful charts for free
                            </MainMessage>
                        </MarginRow>
                        <MarginRow>
                            <Title level={4} style={{color: colors.lightBlue}}>
                                Create urls, save to file or embed in html
                            </Title>
                        </MarginRow>
                        <MarginRow>
                            <Title level={3}>Start creating <ChartRoulette/> today</Title>
                        </MarginRow>
                        <MarginRow>
                            <LandingButton type="primary" size="large">
                                <Link to={AppConfigs.tutorialPath}>
                                    Tutorial
                                </Link>
                            </LandingButton>
                        </MarginRow>
                        <MarginRow>
                            <LandingButton type="default" size="large">
                                <Link to="/app">
                                    Start Charting
                                </Link>
                            </LandingButton>
                        </MarginRow>
                    </FeatureDiv>
                </Col>
                <Col
                    style={{alignItems: "center", display: "flex", flexDirection: "column"}}
                    xs={0} sm={0} md={0} lg={14} xl={14}>
                    <LandingSplashChart chartId="user-stats" group="explore" chartKey="a8fLl8h5"/>
                </Col>
            </Row>
            <Row>
                <Col xs={24} sm={24} md={24} lg={0} xl={0}>
                    <br/>
                    <LandingSplashChart chartId="user-stats" group="explore" chartKey="a8fLl8h5"/>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default LandingSplash
