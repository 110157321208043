// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:baf00372-aa9e-40d1-8b9b-808fd80af847",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_t0tOq6Ce8",
    "aws_user_pools_web_client_id": "7b0nteh5l8uh4pt9on85bcjnib",
    "oauth": {
        "domain": "chart-it-dev.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3000/",
        "redirectSignOut": "http://localhost:3000/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "chart-it-storage-dev",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_content_delivery_bucket": "chart-it-20191219214321-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "http://chart-it-20191219214321-hostingbucket-dev.s3-website-us-east-1.amazonaws.com"
};


export default awsmobile;