import { Button, Table } from "antd"
import styled from "styled-components"

export const ExploreTable = styled(Table)`
    margin: 20px;
`

export const MoreButton = styled(Button)`
    margin-bottom: 8px;
`
