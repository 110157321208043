import { Typography } from "antd"
import React from "react"
import LandingFeaturesRow from "./LandingFeaturesRow"
import { LandingVideo } from "./LandingSplash/style"

const { Title } = Typography

const LandingFeatures: React.FunctionComponent = () => {

    const features = [
        {
            title: "Step 1: Load Data",
            text: "Simply paste data directly from Excel, csv or text. Chart It never sends your data anywhere." +
                "Chart It will automatically parse the data into columns and determine the delimiter" +
                "and column types. Optionally, you can review and override data type mappings.",
            videoSrc: "videos/demo-data-input.mp4",
        },
        {
            title: "Step 2: Create Chart",
            text: `Create a chart by selecting your data, chart type, label column and additional
                columns you want to include in your chart. Name and resize the chart as desired.
                Charts can then be saved to png. As with data, your chart never leaves your computer
                unless you choose to share.`,
            videoSrc: "videos/demo-create-chart.mp4",
        },
        {
            title: "Step 3: Configure Chart",
            text: `Once a chart is created, it can be configured. Depending on the type of chart,
                there are different possible options. Options are reflected as soon as they're
                set so you should see the changes immediately.`,
            videoSrc: "videos/demo-chart-options.mp4",
        },
        {
            title: "Step 4: Share Chart",
            text: `After you're happy with the chart, you can save the chart to png or create a public
                link to the page you're working on. Make sure to note the link down because in guest
                mode, there is no way to recover a lost link. Anyone with that link will be able to
                view your chart and data, although they may not edit it.`,
            videoSrc: "videos/demo-share-chart.mp4",
        },
    ]

    return(
        <React.Fragment>
            <Title level={2}>Creating Charts</Title>
            {features.map(({title, text, videoSrc}) =>
                <LandingFeaturesRow
                    key={title}
                    title={title}
                    text={text}
                    rightSide={<LandingVideo controls src={videoSrc}/>}/>)}
        </React.Fragment>
    )
}

export default LandingFeatures
