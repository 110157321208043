import { Collapse } from "antd"
import React from "react"
import { ChartDefaults } from "../../../../../../config"
import { OptionsCollapsePanel } from "../../../../../../style"
import { ChartKey } from "../../../../../../types"
import { buildOption as b } from "../../utils"

interface HoverProps {
    chartKey: ChartKey
}

const Hover: React.FunctionComponent<HoverProps> = (props) => {
    const { chartKey } = props
    const header = "Hover"
    const prefix = "hover"

    return(
        <Collapse bordered={false} style={{width: "100%"}} key={`${chartKey}${prefix}`}>
            <OptionsCollapsePanel header={header} key={"header"}>
                {b(
                    chartKey,
                    "Mode",
                    "chartjs.hover.mode",
                    "enum",
                    ["point", "nearest", "single", "label", "index", "x-axis", "dataset", "x", "y"],
                )}
                {b(
                    chartKey,
                    "Intersect",
                    "chartjs.hover.intersect",
                    "boolean",
                )}
                {b(
                    chartKey,
                    "Axis",
                    "chartjs.hover.axis",
                    "enum",
                    ["x", "y", "xy"],
                )}
                {b(
                    chartKey,
                    "Animation Duration",
                    "chartjs.hover.animationDuration",
                    "number",
                )}
            </OptionsCollapsePanel>
        </Collapse>
    )
}

export default Hover
