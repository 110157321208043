import { Collapse, Typography } from "antd"
import React from "react"
import { AppConfigs } from "../app/config"
import { FaqCollapse, FullWidthPagraph } from "./style"

const { Panel } = Collapse
const { Title } = Typography

const LandingFaq: React.FunctionComponent = () => {
    return(
        <React.Fragment>
            <Title level={2}>Frequently Asked Questions</Title>
            <FaqCollapse>
                <Panel header="Does my data or chart get sent anywhere?" key="1">
                    <FullWidthPagraph>
                        No. Data does not get sent anywhere unless you choose to share. When
                        you share, data is stored in the cloud and is made available to anyone
                        with a link.
                    </FullWidthPagraph>
                    </Panel>
                <Panel header="What charting library are you using?" key="2">
                <FullWidthPagraph>
                    We current support&nbsp;
                    <a href="https://developers.google.com/chart">Google Charts</a>
                    &nbsp;and&nbsp;
                    <a href="https://www.chartjs.org/">chartjs</a>
                </FullWidthPagraph>
                </Panel>
                <Panel header="I created a chart and shared it but lost the url. Can I get it back?" key="3">
                    <FullWidthPagraph>
                        No. Since charts can be made anonymously, I have no way of tracking down what
                        chart you made. Consider creating an account (coming soon). Sign up for the wait
                        list by emailing <a href={`mailto:${AppConfigs.waitlistContact}`}>{AppConfigs.waitlistContact}</a>
                    </FullWidthPagraph>
                </Panel>
                <Panel header="Can I create a custom url?" key="4">
                    <FullWidthPagraph>
                        This will be a feature in the upcoming version. Sign up for the wait list by
                        emailing <a href={`mailto:${AppConfigs.waitlistContact}`}>{AppConfigs.waitlistContact}</a>
                    </FullWidthPagraph>
                </Panel>
                <Panel header="Can I import data directly from an Excel, csv, tsv or text file?" key="5">
                    <FullWidthPagraph>
                        No. Chart It is intended to help visualize data and prepare charts. Table data in spreadsheets
                        often comes in a messy format with column groups and additional information. Preparing table
                        data is often handled in a dedicated program such as Excel, <a href="http://sheets.google.com/">
                        Google Sheets</a>, <a href="https://www.openoffice.org/">Open Office</a> or similar spreadsheet
                        management software. Chart It is made to be used in conjunction with these tools.
                    </FullWidthPagraph>
                </Panel>
                <Panel header="Can you support a [chart-type] | [chart-option]?" key="6" style={{width:"100%"}}>
                    <FullWidthPagraph>
                        Email support at <a href={`mailto:${AppConfigs.supportContact}`}>{AppConfigs.supportContact}</a> with any
                        feature requests
                    </FullWidthPagraph>
                </Panel>
            </FaqCollapse>
        </React.Fragment>
    )
}

export default LandingFaq
