import { zip } from "lodash"
import { ChartProps } from "../types"
import { formatRaw } from "./dataUtils"

export const drawChart = (
    props: ChartProps,
    callback?: (c: google.visualization.ChartBaseRenderable) => void,
) => {
    const {
        width,
        height,
        chartRef,
        labelColumns,
        dataColumns,
        chartType,
        options: chartOptions,
    } = props
    if (chartRef.current === null) { return }

    const current = chartRef.current

    const table = new google.visualization.DataTable()

    const allColumns = [...labelColumns, ...dataColumns]
    allColumns.forEach(d => table.addColumn(d.dataFormat, d.name))
    zip(...allColumns.map(c => c.raw.map(r => formatRaw(r, c.dataFormat, c.dateFormat))))
    .forEach(r => table.addRow(r))

    const options = {
        ...chartOptions,
        width,
        height,
    }

    // @ts-ignore
    let chart
    switch (chartType) {
        case "area": {
            chart = new google.visualization.AreaChart(current)
            break
        }
        case "bar": {
            // TODO: why does bar chart look like column chart??
            chart = new google.visualization.BarChart(current)
            break
        }
        case "bubble": {
            chart = new google.visualization.BubbleChart(current)
            break
        }
        case "candlestick": {
            chart = new google.visualization.CandlestickChart(current)
            break
        }
        case "column": {
            chart = new google.visualization.ColumnChart(current)
            break
        }
        case "combo": {
            chart = new google.visualization.ComboChart(current)
            break
        }
        // TODO: wheres the donut??
        // case "donut": {
        //     chart = new google.visualization.???(current)
        //     break
        // }
        // TODO: wheres the gauge??
        // case "gauge": {
        //     chart = new google.visualization.???(current)
        //     break
        // }
        // TODO: figure out google maps api
        case "geo": {
            chart = new google.visualization.GeoChart(current)
            break
        }
        case "histogram": {
            chart = new google.visualization.Histogram(current)
            break
        }
        case "line": {
            chart = new google.visualization.LineChart(current)
            break
        }
        // case "org": {
        //     chart = new google.visualization.OrgChart(current)
        //     break
        // }
        case "pie": {
            chart = new google.visualization.PieChart(current)
            break
        }
        case "scatter": {
            chart = new google.visualization.ScatterChart(current)
            break
        }
        case "stepped area": {
            chart = new google.visualization.SteppedAreaChart(current)
            break
        }
        // case "table": {
        //     chart = new google.visualization.Table(current)
        //     break
        // }
        // case "timeline": {
        //     chart = new google.visualization.Timeline(current)
        //     break
        // }
        // case "treemap": {
        //     chart = new google.visualization.TreeMap(current)
        //     break
        // }
    }

    if (chart && callback) {
        google.visualization.events.addListener(chart, "ready",
        // @ts-ignore
        () => callback(chart))
        // @ts-ignore
        chart.draw(table, options)

        // NOTE: backup since some charts do not trigger a ready event
        setTimeout(() => {
            try {
            // @ts-ignore
            callback(chart)
            } catch {
                //  couldn't get uri
            }
        }, 2000)
    }
    // return chart
    // if (chart) {
    //     current.addEventListener("contextmenu", (e) =>
    //     google.visualization.events.addListener(chart, "ready", () => {
    //         @ts-ignore
    //         current.innerHTML = '<img src="' + chart.getImageURI() + '">'
    //       })
    //     )
    //     google.visualization.events.addListener(chart, "ready", callback)
    // }
}
