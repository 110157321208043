import { Collapse, Select } from "antd"
import React, { useState } from "react"
import { container } from "tsyringe"
import { ChartDataContainer } from "../../../../../../containers/ChartDataContainer"
import { OptionsCollapsePanel } from "../../../../../../style"
import { ChartKey } from "../../../../../../types"
import Axis from "./Axis"
import LineDatasetsInner from "./LineDatasetsInner"
import { DatasetSelect } from "./style"

interface LineDatasetsProps {
    chartKey: ChartKey
}

const LineDatasets: React.FunctionComponent<LineDatasetsProps> = (props) => {
    const { chartKey } = props
    const header = "Datasets"
    const prefix = header.toLowerCase()

    const [selected, setSelected] = useState(0)

    const chartContainer = container.resolve(ChartDataContainer)
    const chartData = chartContainer.getData(chartKey)
    const columns = chartData?.dataColumns || []

    return(
        <Collapse bordered={false} style={{width: "100%"}} key={`${chartKey}${prefix}`}>
            <OptionsCollapsePanel header={header} key={"header"}>
                <DatasetSelect onSelect={v => typeof v === "number" && setSelected(v)} value={selected}>
                    {columns.map((c, idx) =>
                        <Select.Option value={idx} key={c.key}>
                            {c.name}
                        </Select.Option>,
                    )}
                </DatasetSelect>
                <Axis chartKey={chartKey} selected={selected}/>
                <LineDatasetsInner chartKey={chartKey} selected={selected}/>
            </OptionsCollapsePanel>
        </Collapse>
    )
}

export default LineDatasets
