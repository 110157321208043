import shortid from "shortid"
import { ChartDefaults } from "../../config"
import { ChartDataProps } from "../../types"

export const emptyChartDataProps = (key?: string): ChartDataProps => (
    {
        name: "",
        nameWithType: "",
        key: key || shortid.generate(),
        notes: "",
        height: ChartDefaults.height,
        width: ChartDefaults.width,
        labelColumns: [],
        dataColumns: [],
        options: {
            chartjs: {...ChartDefaults.chartjs},
            google: {...ChartDefaults.google},
        },
    })
