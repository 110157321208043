import { Card, Col, Row, Typography } from "antd"
import { min, range } from "lodash"
import React, { useEffect, useLayoutEffect, useState } from "react"
import { Link } from "react-router-dom"
import ChartItLoading from "../app/components/main/chart/ChartItLoading"
import { AppConfigs } from "../app/config"
import MainHeader from "../components/header"
import { ExploreAppLoayout, LandingAppLayout } from "../style"
import { Api } from "../utils/apiUtils"
import { Metadata } from "../utils/types"
import { MoreButton } from "./style"

const ExploreApp: React.FunctionComponent = () => {
    const numCharts = 10
    const singleRowWidth = 767

    const [metadatas, setMetadatas] = useState<Metadata[]>([])
    const [endCharts, setEndCharts] = useState(numCharts)

    useEffect(() => {
        Api.listAllCharts("explore").then(setMetadatas)
    }, [])

    useLayoutEffect(() => {
        if (metadatas.length > 0) {
            const idsArg = JSON.stringify(metadatas.slice(endCharts - numCharts, endCharts).map(m => m.path))
            const script = document.createElement("script")
            script.src = AppConfigs.apiFile
            script.async = false
            script.setAttribute("path", idsArg)
            script.setAttribute("attachId", idsArg)
            script.setAttribute("height", "300px")
            script.setAttribute("width", "100%")
            document.body.appendChild(script)
        }
    }, [endCharts, metadatas])

    if (metadatas.length === 0) { return <ChartItLoading/> }

    return(
        <React.Fragment>
            <MainHeader/>
            <LandingAppLayout>
                <ExploreAppLoayout>
                    <Typography.Title>Explore</Typography.Title>
                    {range(min([endCharts / 2, metadatas.length / 2]) || 0).map((idx: number) => {
                        const [left, right] = [metadatas[idx * 2], metadatas[idx * 2 + 1]]
                        const leftLink = `${AppConfigs.chartsPath}/${left.path}`
                        const rightLink = right === undefined ? "" : `${AppConfigs.chartsPath}/${right.path}`
                        return (
                            <React.Fragment key={`card.${left.path}`}>
                                <Row align="middle" gutter={[16, 16]}>
                                    <Col span={window.innerWidth < singleRowWidth ? 24 : 12}>
                                        <Card title={<Link to={leftLink}>{left.name}</Link>}>
                                            <div id={left.path}></div>
                                        </Card>
                                    </Col>
                                    <Col xs={0} sm={0} md={12} lg={12} xl={12}>
                                        {(right === undefined || window.innerWidth < singleRowWidth) ? null :
                                            <Card title={<Link to={rightLink}>{right.name}</Link>}>
                                                <div id={right.path}></div>
                                            </Card>
                                        }
                                    </Col>
                                </Row>
                                {right === undefined || window.innerWidth >= singleRowWidth ? null :
                                    <Row align="middle" type="flex" gutter={[16, 16]}>
                                        <Col span={24}>
                                            <Card title={<Link to={leftLink}>{left.name}</Link>}>
                                                <div id={right.path}></div>
                                            </Card>
                                        </Col>
                                    </Row>
                                }
                            </React.Fragment>
                            )
                    })}
                    <br/>
                    <MoreButton
                        disabled={endCharts >= metadatas.length}
                        onClick={() => setEndCharts(prior => prior + numCharts)}
                    >See More</MoreButton>
                </ExploreAppLoayout>
            </LandingAppLayout>
        </React.Fragment>
    )
}

export default ExploreApp
