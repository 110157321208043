import { Icon } from "antd"
import styled from "styled-components"
import colors from "../../../colors"

export const AppToolbar = styled.div`
    display: inline-flex;
    width: 100%;
    background-color: ${colors.tan};
    height: 30px;
    overflow-x: scroll;
    margin-top: 64px;
    position: absolute;
    align-items: center;
    box-shadow: 0px 1px;
    scrollbar-width: none;
    overflow: hidden;
`

export const ToolbarIcon = styled(Icon)`
    padding: 5px;
    font-size: 24px;
    margin-left: 5px;
`

export const ToolbarIconDisabled = styled(ToolbarIcon)`
    color: grey;
`

export const ToolbarDivider = styled.div`
    font-size: 24px;
    margin-left: 5px;
    color: grey;
    padding-bottom: 4px;

`
