import shortid from "shortid"
import { injectable, singleton } from "tsyringe"
import { Container } from "unstated"
import { AppConfigs } from "../config"

interface MetaContainerProps {
    sticky: boolean
    lastSelected: string | undefined
    readOnly: boolean
    group: string
    chartId: string
}

const emptyState = {
    sticky: false,
    readOnly: false,
    lastSelected: undefined,
    group: AppConfigs.publicGroup,
    chartId: shortid.generate(),
}

@injectable()
@singleton()
export class MetaContainer extends Container<MetaContainerProps> {
    public state = emptyState

    public constructor() {
        super()
    }

    public reset = () => this.setState(emptyState)

    public resetChartId = () => this.setState({chartId: shortid.generate()})

    public toggleSticky = () => this.setState(prior => ({sticky: !prior.sticky}))

    public updateLastSelected = (lastSelected: string | undefined) => this.setState({lastSelected})

    public update = (group: string, chartId: string, readOnly: boolean) => {
        const dev = process.env.NODE_ENV === "development"
        this.setState({group, chartId, readOnly: dev ? false : readOnly})
    }

    public clearLastSelected = () => this.setState({lastSelected: undefined})

}
