import React from "react"
import { AppFooter, FooterText } from "./style"

const MainFooter: React.FunctionComponent = () => {
    return(
        <AppFooter>
            <FooterText>Chart It, {new Date().getFullYear()}</FooterText>
        </AppFooter>
    )
}

export default MainFooter
