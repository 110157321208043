import { useEffect, useRef } from "react"

export const transpose = <T>(array: T[][]) => array.length === 0 ? [] : array[0].map((_, i) => array.map(row => row[i]))

export const isNumeric = (value: string) =>
  // matches "  -$48,123  "
  /^ {0,}-?([$₱ƒ₡₼¥¢៛£€R؋])?([0-9]{1,3}(,?[0-9]{3})*(\.[0-9]+)?|\.[0-9]+) {0,}([$ƒ₼¥₱¢£₡€៛R])?$/.test(value)

export const usePrevious = <T extends {}>(value: T) => {
  const ref = useRef<T>()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export const isDefined = <T>(val: T | undefined): val is T => val !== undefined

export const isNumericArray = (arr: any): arr is number[] =>
  Array.isArray(arr) && arr.every((v: any) => typeof v === "number")

export const isStringArray = (arr: any): arr is string[] =>
  Array.isArray(arr) && arr.every((v: any) => typeof v === "string")
