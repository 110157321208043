// earthy green
const colors = {
    // appHeader: "#4F6D7A", earthy
    lightBlue: "#364d79",
    blue: "#26306c",
    tan: "#bdb3ad",
    grey: "#adb5bd", //"#C0D6DF",
    green: "#2ecc71",
    // mainBackground: "white",
    // mainBackground: "#dfe6e9",
    mainBackground: "#f8f9fa",
    white: "#fff",
    black: "#000000"
}

export default colors
