import { Layout, Collapse } from "antd"
import React from "react"
import { container } from "tsyringe"
import { ChartDataContainer } from "../../../../containers/ChartDataContainer"
import { SiderCollapse, SiderCollapsePanel } from "../../../../style"
import { ChartKey, ChartType } from "../../../../types"
import { getChartOptions } from "./utils"

interface ChartOptionsManagerProps {
    chartKey: ChartKey
    chartType: ChartType
}

const ChartOptionsManager: React.FunctionComponent<ChartOptionsManagerProps> = (props) => {
    const { chartKey, chartType } = props
    const options = getChartOptions(chartType, chartKey)

    const chartDataContainer = container.resolve(ChartDataContainer)
    const chartName = chartDataContainer.getData(chartKey)?.nameWithType

    return(
        <SiderCollapse defaultActiveKey={["Options"]}>
            <SiderCollapsePanel style={{width: "100%"}} header={`${chartName} Options`} key="Options">
                {options}
            </SiderCollapsePanel>
        </SiderCollapse>
    )
}

export default React.memo(ChartOptionsManager)
