import Amplify, { Analytics, Storage } from "aws-amplify"
import React, { useCallback, useEffect, useState } from "react"
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom"
import "reflect-metadata"
import { container } from "tsyringe"
import { Provider } from "unstated"
import ChartItApp from "./app/ChartItApp"
import ChartItLoading from "./app/components/main/chart/ChartItLoading"
import { AppConfigs } from "./app/config"
import { containerInstances } from "./app/containers/instances"
import { MetaContainer } from "./app/containers/MetaContainer"
import awsconfig from "./aws-exports"
import ExploreApp from "./explore/ExploreApp"
import ChartNotFound from "./landing/ChartNotFound"
import LandingApp from "./landing/LandingApp"

Amplify.configure(awsconfig)

Storage.configure({level: "public"})
const analyticsConfig = {
    AWSPinpoint: {
          // Amazon Pinpoint App Client ID
          appId: "3d369ebac47643a88bc19a98cc6890d5",
          // Amazon service region
          region: "us-east-1",
          mandatorySignIn: false,
    },
}

Analytics.configure(analyticsConfig)

Analytics.autoTrack("session", {
    enable: true,
    attributes: {
        attr: "attr",
    },
    provider: "AWSPinpoint",
})

Analytics.autoTrack("pageView", {
    enable: true,
    eventName: "pageView",
    attributes: {
        attr: "attr",
    },
    type: "multiPageApp",
    provider: "AWSPinpoint",
    getUrl: () => {
        return window.location.origin + window.location.pathname
    },
})

const App: React.FunctionComponent = () => {
    const [loadedGoogle, setLoadedGoogle] = useState(false)
    const [loadedChartsJs, setLoadedChartsJs] = useState(true)
    const metaContainer = container.resolve(MetaContainer)

    const loadGoogle = useCallback(() => {
        if (!window.google || !window.google.visualization) {
            const script = document.createElement("script")
            script.src = "/loader.js"
            script.async = false
            script.onload = () => {
              google.charts.load(
                  "current",
                  {
                      packages: ["corechart", "geochart"],
                    mapsApiKey: "AIzaSyD-9tSrke72PouQMnMX-a7eZSW0jkFMBWY",
                })
              google.setOnLoadCallback(() => setLoadedGoogle(true))
            }
            document.body.appendChild(script)
          } else {
            setLoadedGoogle(true)
          }
        }, [])

    useEffect(() => {
        // load google
        loadGoogle()
    }, [metaContainer])

    if (!loadedGoogle || !loadedChartsJs) {
        return <ChartItLoading />
    }
    return(
        <Provider inject={containerInstances}>
            <BrowserRouter>
                <Switch>
                    <Route exact path={`${AppConfigs.chartsPath}/:group/:id`} render={({match}) =>
                        <ChartItApp
                            metadata={{group: match.params.group, id: match.params.id}}
                            tutorial={false}
                        />}
                    />
                    <Route exact path={`${AppConfigs.chartsPath}/:group/:id/:chartKey`} render={({match}) =>
                        <Redirect to={`${AppConfigs.chartsPath}/${match.params.group}/${match.params.id}`}/>}
                    />
                    <Route exact path={`${AppConfigs.tutorialPath}`} render={() =>
                        <ChartItApp tutorial={true}/>
                    }></Route>
                    <Route exact
                        path={AppConfigs.appPath}
                        render={() => <ChartItApp tutorial={false}/>}
                    />
                    <Route exact path={AppConfigs.explorePath} component={ExploreApp}/>
                    <Route exact path={AppConfigs.chartNotFoundPath} component={ChartNotFound}/>
                    <Route exact path={AppConfigs.apiPath}
                        render={() => <LandingApp scrollId={"api"}/>}
                    />
                    <Route exact path="/" component={LandingApp}/>
                    <Redirect to="/"/>
                </Switch>
            </BrowserRouter>
        </Provider>
    )
}

export default App
