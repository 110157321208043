import { Col, Divider, Row, Typography } from "antd"
import React from "react"
import iconFeatureCustomizable from "../images/icon-feature-customizable.svg"
import iconFeatureSecure from "../images/icon-feature-secure.svg"
import iconFeatureSimple from "../images/icon-feature-simple.svg"
import { LandingVideo } from "./LandingSplash/style"
import { FeatureDiv, FeatureImage } from "./style"

const { Title, Paragraph } = Typography

const LandingDemo: React.FunctionComponent = () => {
    return(
        <React.Fragment>
            <LandingVideo controls src="videos/demo-simple.mp4"/>
            <Divider/>
            <Row type="flex" justify="center">
                <Col xs={0} sm={0} md={6} lg={6} xl={6}>
                    <FeatureDiv>
                        <FeatureImage alt="simple" src={iconFeatureSimple} width="75px"/>
                        <br/><br/>
                        <Title level={4}>Simple</Title>
                        <Paragraph>
                            Simply paste data directly from Excel, csv or text.
                            Parsing and validations are automatically performed with available overrides
                        </Paragraph>
                    </FeatureDiv>
                </Col>
                <Col xs={0} sm={0} md={6} lg={6} xl={6}>
                    <FeatureDiv>
                        <FeatureImage alt="customizable" src={iconFeatureCustomizable} width="75px"/>
                        <br/><br/>
                        <Title level={4}>Customizable</Title>
                        <Paragraph>
                            Choose from 12 charts and dozens of formatting options.
                            Add multiple charts and datasets on the same page.
                        </Paragraph>
                    </FeatureDiv>
                </Col>
                <Col xs={0} sm={0} md={6} lg={6} xl={6}>
                    <FeatureDiv>
                        <FeatureImage alt="secure" src={iconFeatureSecure} width="75px"/>
                        <br/><br/>
                        <Title level={4}>Private</Title>
                        <Paragraph>
                            Chart It never sends your data anywhere when creating or exporting charts.
                            Only when sharing do they get stored and become public.
                            <br/>
                            <a href="https://developers.google.com/chart/interactive/faq">
                                Read more about Google Charts.
                            </a>
                        </Paragraph>
                    </FeatureDiv>
                </Col>
            </Row>
            <Row>
                <Col xs={22} sm={22} md={0} lg={0} xl={0}>
                    <FeatureDiv>
                        <FeatureImage alt="simple" src={iconFeatureSimple} width="75px"/>
                        <br/><br/>
                        <Title level={4}>Simple</Title>
                        <Paragraph>
                            Simply paste data directly from Excel, csv or text.
                            Parsing and validations are automatically performed with available overrides
                        </Paragraph>
                    </FeatureDiv>
                </Col>
            </Row>
            <Row>
                <Col xs={22} sm={22} md={0} lg={0} xl={0}>
                        <FeatureDiv>
                            <FeatureImage alt="customizable" src={iconFeatureCustomizable} width="75px"/>
                            <br/><br/>
                            <Title level={4}>Customizable</Title>
                            <Paragraph>
                                Choose from 12 charts and dozens of formatting options.
                                Add multiple charts and datasets on the same page.
                            </Paragraph>
                        </FeatureDiv>
                    </Col>
                </Row>
            <Row>
            <Col xs={22} sm={22} md={0} lg={0} xl={0}>
                    <FeatureDiv>
                        <FeatureImage alt="secure" src={iconFeatureSecure} width="75px"/>
                        <br/><br/>
                        <Title level={4}>Private</Title>
                        <Paragraph>
                            Chart It never sends your data anywhere when creating or exporting charts.
                            Only when sharing do they get stored and become public.
                            <br/>
                            <a href="https://developers.google.com/chart/interactive/faq">
                                Read more about Google Charts.
                            </a>
                        </Paragraph>
                    </FeatureDiv>
                </Col>

            </Row>
        </React.Fragment>
    )
}

export default LandingDemo
