import React from "react"
import { buildOption as b } from "../../utils"

interface YAxisInterface {
    chartKey: string
    rAxisIdx: number
}

const YAxis: React.FunctionComponent<YAxisInterface> = ({chartKey, rAxisIdx}) => {
    return(
        <React.Fragment>
            {b(chartKey, "Display Label", `chartjs.scales.yAxes[${rAxisIdx}].scaleLabel.display`, "boolean")}
            {b(chartKey, "Display Gridlines", `chartjs.scales.yAxes[${rAxisIdx}].gridLines.display`, "boolean")}
            {b(chartKey, "Stacked", `chartjs.scales.yAxes[${rAxisIdx}].stacked`, "boolean")}
            {b(chartKey, "Axis Label", `chartjs.scales.yAxes[${rAxisIdx}].scaleLabel.labelString`, "string")}
            {b(chartKey, "Min", `chartjs.scales.yAxes[${rAxisIdx}].ticks.min`, "number")}
            {b(chartKey, "Max", `chartjs.scales.yAxes[${rAxisIdx}].ticks.max`, "number")}
        </React.Fragment>
    )
}

export default YAxis
