import { Analytics } from "aws-amplify"
import React, { useCallback, useEffect, useState } from "react"
import { Redirect } from "react-router-dom"
import { container } from "tsyringe"
import { Subscribe } from "unstated"
import "../App.css"
import MainHeader from "../components/header"
import { ChartItAppLayout } from "../style"
import ChartItLoading from "./components/main/chart/ChartItLoading"
import MainContainer from "./components/main/chart/MainContainer"
import ChartOptionsManager from "./components/main/chart/options/ChartOptionsManager"
import CollapseManager from "./components/panel/CollapseManager"
import MainToolbar from "./components/toolbar"
import { AppConfigs } from "./config"
import { ChartDataContainer } from "./containers/ChartDataContainer"
import { ChartOptionsContainer } from "./containers/ChartOptionsContainer"
import { DataContainer } from "./containers/DataContainer"
import { MetaContainer } from "./containers/MetaContainer"
import { TutorialContainer } from "./containers/TutorialContainer"
import { AppContent, AppSider, MainLayout } from "./style"
import { ChartItError, ChartMetadata } from "./types"
import { getSavedChart } from "./utils/s3Utils"

interface ChartItAppProps {
  metadata?: ChartMetadata
  tutorial: boolean
}

const ChartItApp: React.FunctionComponent<ChartItAppProps> = (props) => {

  const { metadata, tutorial } = props

  const [error, setError] = useState<ChartItError>()
  const [loading, setLoading] = useState(true)
  const [, setHideSider] = useState(false)

  const dataContainer = container.resolve(DataContainer)
  const chartDataContainer = container.resolve(ChartDataContainer)
  const metaContainer = container.resolve(MetaContainer)
  const tutorialContainer = container.resolve(TutorialContainer)

  const loadChart = useCallback(
    (md: ChartMetadata) => {
      dataContainer.reset()
      metaContainer.update(md.group, md.id, true)
      getSavedChart(md)
        .then(r => {
            dataContainer.loadData(r.data)
            chartDataContainer.loadData(r.chartData)
            setHideSider(true)
            setLoading(false)
        }).catch(e => setError(e))
    },
    [chartDataContainer, dataContainer, metaContainer],
  )

  const resetAll = useCallback(() => {
    metaContainer.reset()
    dataContainer.reset()
    chartDataContainer.reset()
    tutorialContainer.clear()
    setHideSider(false)
    setLoading(false)
  }, [tutorialContainer, dataContainer, chartDataContainer, metaContainer, setHideSider, setLoading])

  useEffect(() => {
    if (metadata) {
      Analytics.record({name: "Chart Loaded", attributes: {metadata}})
      loadChart(metadata)
    } else {
      resetAll()
    }
  }, [metadata, loadChart, resetAll])

  useEffect(() => {
    if (tutorial) {
      const tc = container.resolve(TutorialContainer)
      tc.init()
    }
  }, [tutorial])

  if (error) {
    return <Redirect to={AppConfigs.chartNotFoundPath} />
  }

  if (loading) { return <ChartItLoading/> }

  return (
    <MainLayout>
        <MainHeader/>
        <MainToolbar/>
          <ChartItAppLayout>
            <Subscribe to={[ChartOptionsContainer, MetaContainer]}>{
              (chartOptionsContainer: ChartOptionsContainer, mc: MetaContainer) => {
                const {key, type} = chartOptionsContainer.getSelectedChartAndType()
                const displayOptions = key && type
                return (
                  <React.Fragment>
                    <AppSider
                      theme="light"
                      width="360px"
                      collapsedWidth="30px"
                      // style={{display: hideSider || displayOptions ? "none" : "flex"}}
                      collapsed={mc.state.sticky === false && mc.state.lastSelected === undefined}
                      style={{
                        display: displayOptions ? "none" : "flex",
                      }}
                    >
                      <CollapseManager/>
                    </AppSider>
                    <AppSider
                      theme="light"
                      width="360px"
                      style={{display: displayOptions ? "flex" : "none"}}
                    >
                      <ChartOptionsManager chartKey={key} chartType={type}/>
                    </AppSider>
                  </React.Fragment>
                )}}
              </Subscribe>
            <AppContent>
              <MainContainer/>
            </AppContent>
        </ChartItAppLayout>
    </MainLayout>
  )
}

// TODO: Adding to element directly in browser works???
export default ChartItApp
