import chartjs, { ChartDataSets } from "chart.js"
import { groupBy, map, max, range, zip } from "lodash"
import React from "react"
import ChartComponent, {
  Bar,
  Bubble,
  ChartComponentProps,
  ChartData,
  Doughnut,
  HorizontalBar,
  Line,
  Pie,
  Polar,
  Radar,
  Scatter,
 } from "react-chartjs-2"
import { ChartJsOptions, ChartType, DataColumn, DataFormatType } from "../../../../types"
import { isDefined, isNumericArray } from "../../../../utils/utils"
import { formatRaw } from "../../../../utils/dataUtils"

interface ChartJsChartProps {
    labelColumns: DataColumn[],
    dataColumns: DataColumn[],
    chartType?: ChartType,
    options: ChartJsOptions,
    setChartUri: (uri: string) => void
}

const ChartJsChart: React.FunctionComponent<ChartJsChartProps> = (props) => {
    const {
        labelColumns,
        dataColumns,
        chartType,
        options: origOptions,
        setChartUri,
      } = props
    let Chart: JSX.Element = <div></div>
    const chartRef = React.createRef<ChartComponent<ChartComponentProps>>()

    if (labelColumns.length === 0 || dataColumns.length === 0) { return null }

    const updateChartUri = () => {
      const chartUri = chartRef.current?.chartInstance.canvas?.getContext("2d")?.canvas.toDataURL("image/png")
      if (chartUri !== undefined) {
        setChartUri(chartUri)
      }
    }

    const options = {...origOptions, animation: {onComplete: updateChartUri}}
    const datasetOptions = options.chartDataSets
    const label = labelColumns[0]
    const labels = label.raw.map(r => formatRaw(r, label.dataFormat, label.dateFormat))

    const flattenString = (cd: ChartDataSets[], key: keyof ChartDataSets) =>
      cd.map(c => {
        const strValue = c[key]
        return typeof strValue === "string" ? strValue : undefined
      }).filter(isDefined)

    const supportsType = (type: ChartType) =>
      new Set([ChartType.chartjs_bar, ChartType.chartjs_line, ChartType.chartjs_scatter]).has(type)

    const shouldFlatten = (type: ChartType) =>
      new Set([ChartType.chartjs_doughnut, ChartType.chartjs_pie, ChartType.chartjs_polar]).has(type)

    const prepareData = (
      cols: DataColumn[], opts: ChartDataSets[], lbls: Array<number | string | Date>, type: ChartType,
    ) => {
      const flat = shouldFlatten(type)
      const overrideType = !supportsType(type)
      const datasets = zip(cols, opts)
        .map(([c, o]) => {
          if (c === undefined) { return undefined }

          const unpackedOpts = flat === false ?
            o :
            {
              ...o,
              backgroundColor: flattenString(opts, "backgroundColor"),
              borderColor: flattenString(opts, "borderColor"),
              pointBorderColor: flattenString(opts, "pointBorderColor"),
              pointBackgroundColor: flattenString(opts, "pointBackgroundColor"),
              pointHoverBackgroundColor: flattenString(opts, "pointHoverBackgroundColor"),
              pointHoverBorderColor: flattenString(opts, "pointHoverBorderColor"),
            }
          const out: ChartDataSets = {
            ...unpackedOpts,
            label: unpackedOpts?.label || c.name,
            fill: false,
            data: c.raw.map(r => formatRaw(r, DataFormatType.number)) as number[],
            type: overrideType ? undefined : o?.type,
          }
          return out
        })
        .filter(isDefined)

      const data: ChartData<chartjs.ChartData> = {
        labels: lbls,
        datasets,
      }
      return data
    }

    const datasetToPoint = (
      lbls: Array<string | number | Date >,
      ds: readonly chartjs.ChartDataSets[],
      opts: readonly chartjs.ChartDataSets[],
    ): readonly chartjs.ChartDataSets[] => {
      if (ds.length === 0) { return ds }
      const datasetData = ds.map(d => d === undefined ? undefined : d.data)
      const [xs, ys, rs, ts] = datasetData
      const len = max([xs?.length, ys?.length, rs?.length, ts?.length]) || 0
      const points = range(0, len).map(idx => {
        const lbl = lbls[idx]
        const xRaw = xs ? xs[idx] : undefined
        const yRaw = ys ? ys[idx] : undefined
        const rRaw = rs ? rs[idx] : undefined
        const tRaw = ts ? ts[idx] : undefined
        const x = typeof xRaw === "number" ? xRaw : undefined
        const y = typeof yRaw === "number" ? yRaw : undefined
        const r = typeof rRaw === "number" ? rRaw : undefined
        const t = typeof tRaw === "number" ? tRaw : undefined

        if (x !== undefined && y !== undefined && r !== undefined && t !== undefined) {
          return {lbl, x, y, r, t}
        } else if (x !== undefined && y !== undefined && r !== undefined) {
          return {lbl, x, y, r}
        } else if (x !== undefined && y !== undefined) {
          return {lbl, x, y}
        } else if (x !== undefined) {
          return {lbl, x}
        } else {
          return {lbl}
        }
      })

      const updated = map(groupBy(points, "label"), (d, label) => ({label, data: d}))
      const updatedWithOptions = updated.map((d, idx) => ({...opts[idx], ...d}))
      return updatedWithOptions
    }

    switch (chartType) {
      case ChartType.chartjs_bar: {
        const data = prepareData(dataColumns, datasetOptions, labels, chartType)
        Chart = <Bar data={data} options={{...options, animation: {onComplete: updateChartUri}}} ref={chartRef}/>
        break
      }
      case ChartType.chartjs_bubble: {
        // format is {x: number, y: number, r: number}
        const data = prepareData(dataColumns, datasetOptions, labels, chartType)
        const updatedDatasets = data.datasets === undefined ?
          data.datasets :
          datasetToPoint(labels, data.datasets, datasetOptions)
        const updatedData = {...data, datasets: updatedDatasets}
        Chart = <Bubble data={updatedData} options={options} ref={chartRef}/>
        break
      }
      case ChartType.chartjs_doughnut: {
        const data = prepareData(dataColumns, datasetOptions, labels, chartType)
        Chart = <Doughnut data={data} options={options} ref={chartRef}/>
        break
      }
      case ChartType.chartjs_horizontal_bar: {
        const data = prepareData(dataColumns, datasetOptions, labels, chartType)
        Chart = <HorizontalBar data={data} options={options} ref={chartRef}/>
        break
      }
      case ChartType.chartjs_pie: {
        const data = prepareData(dataColumns, datasetOptions, labels, chartType)
        Chart = <Pie data={data} options={options} ref={chartRef}/>
        break
      }
      case ChartType.chartjs_polar: {
        const data = prepareData(dataColumns, datasetOptions, labels, chartType)
        Chart = <Polar data={data} options={options} ref={chartRef}/>
        break
      }
      case ChartType.chartjs_radar: {
        const data = prepareData(dataColumns, datasetOptions, labels, chartType)
        Chart = <Radar data={data} options={options} ref={chartRef}/>
        break
      }
      case ChartType.chartjs_line: {
        const data = prepareData(dataColumns, datasetOptions, labels, chartType)
        if (
            labelColumns[0]?.dataFormat === DataFormatType.date
            && options.scales
            && options.scales.xAxes
            && options.scales.xAxes.length > 0
          ) {
            // without this x axes will show long form labels and be set as category
          options.scales.xAxes[0].type = "time"
        }
        let updatedData = data
        if (isNumericArray(labels)) {
          const datasets = data.datasets?.map(d => {
            const datas = d.data
            if (isNumericArray(datas)) {
              const updated = zip(datas, labels).map(([y, x]) => ({y, x}))
              return {...d, data: updated}
            } else {
              return datas
            }
          })

          // @ts-ignore
          updatedData = datasets ? {...data, datasets} : data
        }

        Chart = <Line data={updatedData} options={options} ref={chartRef}/>
        break
      }
      case ChartType.chartjs_scatter: {
        const data = prepareData(dataColumns, datasetOptions, labels, chartType)
        const updatedDatasets = data.datasets === undefined ?
          data.datasets :
          datasetToPoint(labels, data.datasets, datasetOptions)
        const updatedData = {...data, datasets: updatedDatasets}
        Chart = <Scatter data={updatedData} options={options} ref={chartRef}/>
        break
      }
      default: {
        break
      }
    }

    return(
        <React.Fragment >
          <div style={{width: "100%", height: "100%", padding: "20px"}}>
            {Chart}
          </div>
        </React.Fragment>
    )
}

export default ChartJsChart
