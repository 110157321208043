import React from "react"
import logo from "../../../../images/logo-transparent-128.png"
import { BouncyDiv, LoadingBackground } from "../../../style"

const ChartItLoading: React.FunctionComponent = () => {
    return(
        <LoadingBackground>
            <BouncyDiv>
                <img src={logo} alt="Chart It"/>
            </BouncyDiv>
        </LoadingBackground>
    )
}

export default ChartItLoading
