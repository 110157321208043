import React from "react"
import { Subscribe } from "unstated"
import { ChartDataContainer } from "../../../containers/ChartDataContainer"
import { DataContainer } from "../../../containers/DataContainer"
import { MetaContainer } from "../../../containers/MetaContainer"
import { save } from "../../../utils/shareUtils"
import Chart from "./Chart"

const ChartContainer: React.FunctionComponent = () => {

    return(
        <Subscribe to={[DataContainer, ChartDataContainer, MetaContainer]}>
            {(dataContainer: DataContainer, chartContainer: ChartDataContainer, metaContainer: MetaContainer) => {
                return chartContainer.state.datas.map(c => {
                    // if (!c.type || !c.dataKey || c.labelColumns.length === 0 || c.dataColumns.length === 0) {
                    //     return null
                    // }
                    const data = c.dataKey ? dataContainer.getData(c.dataKey) : undefined
                    if (data === undefined) { return null }
                    const allData = dataContainer.getAll()
                    const allChartData = chartContainer.getDatas()
                    return (
                        <Chart
                            key={c.key}
                            name={c.nameWithType}
                            group={metaContainer.state.group}
                            chartKey={c.key}
                            chartId={metaContainer.state.chartId}
                            labelColumns={c.labelColumns}
                            dataColumns={c.dataColumns}
                            width={c.width}
                            height={c.height}
                            dataName={data.name}
                            resize={size => chartContainer.updateSizeWithDelay(c.key, size)}
                            handleOnDelete={() => chartContainer.deleteData(c.key)}
                            chartType={c.type}
                            chartOptions={c.options}
                            saveChart={() =>
                                save(metaContainer.state.group, metaContainer.state.chartId, allData, allChartData)}
                            readOnly={metaContainer.state.readOnly}
                        />
                    )
                })
            }}
        </Subscribe>
    )
}

export default ChartContainer
