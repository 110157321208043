import React from "react"
import { Subscribe } from "unstated"
import { DataContainer } from "../../../containers/DataContainer"
import { AppCard } from "../../../style"
import DataTable from "./DataTable"

const ChartTableContainer: React.FunctionComponent = () => {
    return(
        <Subscribe to={[DataContainer]}>
            {(dataContainer: DataContainer) => {
                const datas = dataContainer.getDatas()
                return datas.map(d =>
                    <AppCard
                        style={{marginLeft: "10px", marginBottom: "10px"}}
                        title={d.nameWithType}
                        key={d.key}
                    >
                        <DataTable columns={d.columns}/>
                    </AppCard>,
                    )
            }}
        </Subscribe>
    )
}

export default ChartTableContainer
