import { Modal, Typography } from "antd"
import React from "react"

const { Text, Paragraph } = Typography

interface ShareResultProps {
    url: string | undefined
    onOk: () => void
}

const ShareResultModal: React.FunctionComponent<ShareResultProps> = (props) => {
    const {onOk, url} = props

    const title = url ? "Chart Saved" : "Error"
    const content = url ?
        <React.Fragment>
            <Paragraph>Chart has been successfully saved and is available at:</Paragraph>
            <Paragraph copyable={{text: url}}>
                <a href={url} target="_blank" rel="noopener noreferrer">{url}</a>
            </Paragraph>
            <Paragraph strong>There is no way to recover this link, so please note it down</Paragraph>
        </React.Fragment> :
        <Text>Something went wrong. Please try again or contact support.</Text>

    if (url) {
        Modal.success({title, content, onOk})
    } else {
        Modal.error({title, content, onOk})
    }

    return(null)
}

export default ShareResultModal
