import { Popover, Typography } from "antd"
import { Analytics } from "aws-amplify"
import { isEqual, omit } from "lodash"
import React, { useState } from "react"
import { ResizableBox } from "react-resizable"
import "react-resizable/css/styles.css"
import { Subscribe } from "unstated"
import { ChartOptionsContainer } from "../../../containers/ChartOptionsContainer"
import { TutorialContainer } from "../../../containers/TutorialContainer"
import { AppIcon, AppIconSelected, ChartCard, IconContainer } from "../../../style"
import { tutorial10, tutorial8 } from "../../../tutorial/tutorialSteps"
import { ChartKey, ChartOptions, ChartType, DataColumn } from "../../../types"
import DeleteModal from "../../modals/DeleteModal"
import HtmlModal from "../../modals/HtmlModal"
import ChartJsChart from "./ChartJsChart"
import GoogleChart from "./GoogleChart"

const { Text } = Typography

interface ChartProps {
  name: string,
  group: string,
  chartId: string
  chartKey: ChartKey
  labelColumns: DataColumn[]
  dataColumns: DataColumn[]
  chartType?: ChartType
  width: number
  height: number
  dataName?: string
  resize: (resizeData: {width: number, height: number}) => void
  handleOnDelete: () => void
  chartOptions: ChartOptions
  saveChart: () => Promise<string | undefined>
  readOnly: boolean
}

const Chart = (props: ChartProps) => {
    const {
      name,
      group,
      chartId,
      chartKey,
      resize,
      labelColumns,
      dataColumns,
      chartType,
      dataName,
      width,
      height,
      handleOnDelete,
      chartOptions,
      saveChart,
      readOnly,
    } = props

    const [chartUri, setChartUri] = useState<string>()

    const handleOnSave = () => {
      if (chartUri) {
        Analytics.record({
          name: "Save Share",
          attributes: {chartType},
        })
        const a  = document.createElement("a")
        a.href = chartUri
        a.download = `${name || "chart"}.png`
        a.click()
      }
    }
    // const [{ opacity }, dragRef] = useDrag({
    //     item: { id: "x", type: "div" },
    //     collect: monitor => ({
    //       opacity: monitor.isDragging() ? 0.5 : 1,
    //     }),
    //   })
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [showHtmlModal, setShowHtmlModal] = useState(false)

    const googleChart = chartType?.startsWith("chartjs") ? false : true

    return(
      <ChartCard title={name} style={{marginLeft: "10px", marginBottom: "10px"}}>
          <ResizableBox
              className="box"
              width={width}
              height={height}
              // onResizeStop={(r, d) => resize(d.size)}
              onResize={(_r, d) => resize(d.size)}
              resizeHandles={["sw", "se", "nw", "ne", "w", "e", "n", "s"]}
          >
            {!googleChart &&
              <ChartJsChart
                labelColumns={labelColumns}
                dataColumns={dataColumns}
                chartType={chartType}
                options={chartOptions.chartjs}
                setChartUri={setChartUri}
              />
            }
            {googleChart &&
              <GoogleChart
                labelColumns={labelColumns}
                dataColumns={dataColumns}
                chartType={chartType}
                width={width}
                height={height}
                options={chartOptions.google}
                setChartUri={setChartUri}
              />}
          </ResizableBox>
            <Text style={{display: "flex"}}>Data: {dataName}</Text>
            {/* <Text style={{display: "flex"}}>Source: {props.comm}</Text> */}
            <IconContainer>
              <AppIcon title="Delete" type="delete" onClick={() => setShowDeleteModal(true)}/>
              <AppIcon title="HTML" type="code" onClick={() => setShowHtmlModal(true)}/>
              <Subscribe to={[ChartOptionsContainer, TutorialContainer]}>{
                (chartOptionsContainer: ChartOptionsContainer, t: TutorialContainer) => {
                const AppIconCurrent = chartOptionsContainer.getSelectedChart() === chartKey ? AppIconSelected : AppIcon
                return (
                  readOnly ? null :
                  <Popover
                    content={tutorial8.content}
                    visible={t.state.curStep === tutorial8.step}
                    placement={tutorial8.placement}
                  >
                    <AppIconCurrent
                      title="Options"
                      type="setting"
                      onClick={() => {
                        t.complete(tutorial8.step)
                        chartOptionsContainer.toggle(chartKey)}
                      }
                    />
                  </Popover>
                )
              }}
              </Subscribe>
              <Subscribe to={[TutorialContainer]}>{(t: TutorialContainer) =>
                <Popover
                  content={tutorial10.content}
                  visible={t.state.curStep === tutorial10.step}
                  placement={tutorial10.placement}
                >
                  <AppIcon
                    title="Save"
                    type="save"
                    onClick={() => {
                      t.complete(tutorial10.step)
                      handleOnSave()
                    }}/>
                </Popover>
              }
              </Subscribe>
            </IconContainer>

          {showDeleteModal &&
            <DeleteModal
              name={name}
              onOk={handleOnDelete}
              onCancel={() => setShowDeleteModal(false)}
            />
          }

          {showHtmlModal &&
              <HtmlModal
                group={group}
                chartId={chartId}
                chartKey={chartKey}
                readOnly={readOnly}
                onCancel={() => setShowHtmlModal(false)}
                saveChart={saveChart}
              />
          }
      </ChartCard>
    )
}

const propsAreEqual = (prevProps: Readonly<ChartProps>, nextProps: Readonly<ChartProps>) =>
  // prevents screen from flickering
  isEqual(omit(prevProps, ["resize", "handleOnDelete", "saveChart"]), omit(nextProps, ["resize", "handleOnDelete", "saveChart"]))

export default React.memo(Chart, propsAreEqual)
