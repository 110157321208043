import { Button, Typography } from "antd"
import React from "react"
import { Link } from "react-router-dom"
import MainHeader from "../components/header"
import chartNotFound from "../images/chart-it-not-found-128.png"
import { AppLayout } from "../style"
import { LandingBlock } from "./style"

const { Text } = Typography

const ChartNotFound: React.FunctionComponent = () => {

    return(
        <AppLayout>
            <MainHeader/>
                <LandingBlock style={{height: "100vh"}}>
                    <img src={chartNotFound} alt="chart not found"/>
                    <Text strong>Chart not found</Text>
                    <Button type="primary" size="large" style={{marginTop: "20px"}}>
                        <Link to="/">
                            Home
                        </Link>
                    </Button>
                    <Button type="primary" size="large" style={{marginTop: "20px"}}>
                        <Link to="/app">
                            Start Charting
                        </Link>
                    </Button>
                </LandingBlock>

        </AppLayout>
    )
}

export default ChartNotFound
