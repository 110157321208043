import { Collapse, Layout, Typography } from "antd"
import styled from "styled-components"
import appColors from "../colors"

export const LandingBlock = styled(Layout.Content)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* margin: 100px; */
    /* padding-left: 10%;
    padding-right: 10%; */
    background-color: ${appColors.mainBackground};
    min-height: calc(100vh - 64px);
    width: 100%;
`

export const FeatureDiv = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    margin-right: 20px;
    max-width: 500px;
`

export const FaqCollapse = styled(Collapse)`
    width: 80%;
`

export const FeatureImage = styled.img`
    box-shadow: -5px 5px gainsboro;
`

export const FullWidthPagraph = styled(Typography.Paragraph)`
    width: calc(80vw - 50px);
    
`
