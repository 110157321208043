import { Checkbox, Input, InputNumber, Select } from "antd"
import React, { useEffect, useRef, useState } from "react"
import { container } from "tsyringe"
import { ChartDataContainer } from "../../../../containers/ChartDataContainer"
import { ChartKey } from "../../../../types"
import { isStringArray } from "../../../../utils/utils"
import { colorInputSelector, isEnumChoice } from "./utils"

interface SelectorProps<T = number | string | boolean | string[] | number[]> {
    chartOptionType: ChartOptionType
    optionPath: string
    initialValue: T | undefined
    choices?: EnumChoice[] | string[]
    allowClear?: boolean
    chartKey: ChartKey
}

const OptionSelector: React.FunctionComponent<SelectorProps> = (props) => {
    const { chartKey, optionPath, chartOptionType, initialValue, allowClear = true, choices = [] } = props

    const [value, setValue] = useState<string|number|boolean|string[]|number[]|undefined>(initialValue)
    const isFirstRun = useRef(true)

    let selector: JSX.Element | null = null

    const chartDataContainer = container.resolve(ChartDataContainer)

    const choicesMapping: Map<string, string | string[] | number[]> = new Map()

    if (isEnumChoice(choices)) {
        // TODO: choices use value when loading saved value
        choices.forEach(c => choicesMapping.set(c.label, c.value))
    } else {
        choices.forEach(c => choicesMapping.set(c, c))
    }

    useEffect(() => {
        // skip updating on initialization
        if (isFirstRun.current) {
            isFirstRun.current = false
            return
          }

        // get value if enum
        const option = choices.length > 0 && typeof value === "string" ? choicesMapping.get(value) : value
        chartDataContainer.updateOption(chartKey, optionPath, option)
    }, [value, chartKey, optionPath, choices, choicesMapping])

    switch (chartOptionType) {
        case "string":
            if (typeof value === "string" || value === undefined) {
                selector = (
                    <Input
                        style={{width: "100%"}}
                        placeholder="Value"
                        onChange={e => setValue(e.target.value || undefined)}
                        value={value}
                    />)
                }
            break
        case "boolean":
            if (typeof value === "boolean" || value === undefined) {
                selector = <Checkbox checked={value} onChange={e => setValue(e.target.checked ? true : false)}/>
            }
            break
        case "color":
            if (typeof value === "string" || value === undefined) {
                selector = colorInputSelector(value, setValue)
            }
            break
        case "tags":
            if (isStringArray(value) || value === undefined) {
                selector = <Select
                    mode="tags"
                    placeholder={"Add value"}
                    value={value}
                    onChange={(c: string[]) => setValue(c)}
                    style={{width: "100%"}}
                />
            }
            break
        case "enum":
            selector = (
                <Select
                    allowClear={allowClear}
                    showSearch style={{width: "100%"}}
                    onChange={setValue} value={value}
                >
                    {Array.from(choicesMapping.keys())
                        .map(c => <Select.Option key={c} value={c}>{c}</Select.Option>)
                    }
                </Select>
            )
            break
        case "number":
            const handleChange = (v: number | undefined | null) => {
                if (typeof v === "string") {
                    setValue(parseFloat(v) || undefined)
                } else if (typeof v === "number") {
                    setValue(v)
                } else {
                    setValue(undefined)
                }
            }
            selector = (
                <InputNumber
                    style={{width: "100%"}}
                    value={typeof value === "number" ? value : undefined}
                    onChange={handleChange} />
            )
            break
    }

    return selector
}

export default OptionSelector
