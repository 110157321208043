import { Col, Select } from "antd"
import React, { useEffect, useState } from "react"
import { WideRow } from "../../../style"

interface ChartColumnSelectorRowProps {
    name: string
    multiple: boolean
    columns: string[]
    selected: string[]
    readOnly: boolean
    handleOnChange: (s: string[]) => void
    onClick?: () => void
}
const ChartColumnSelectorRow: React.FunctionComponent<ChartColumnSelectorRowProps> = (props) => {
    // NOTE: Setting the state in the parent above causes the prop to re-render and the dropdown to collapse.
    // In order to allow multiple selects without collapsing the dropdown, I have to manually keep track
    // of whether the dropdown is in focus
    const { name, multiple, handleOnChange, selected, readOnly, columns, onClick } = props
    const [initial, setInitial] = useState(true)
    const [focus, setFocus] = useState(false)
    const [curSelected, setCurSelected] = useState<string[]>(selected)

    useEffect(() => {
        // initial prevents infinite loop
        if (!multiple || (!focus && !initial)) {
            handleOnChange(curSelected)
        }

        setInitial(false)
    }, [focus, multiple, curSelected, handleOnChange, initial])

    const handleOnChangeWrapper = (cols: string | string[]) => {
        // if multiple off, returns string else returns string[]
        const colsArr = typeof(cols) === "string" ? [cols] : cols
        setCurSelected(colsArr)
        handleOnChange(colsArr)
        onClick && onClick()
    }

    return(
        <WideRow key={name}>
            <Col span={8}>{name}</Col>
            <Col span={16}>
                <Select
                    autoClearSearchValue={false}
                    mode={multiple ? "multiple" : "default"}
                    style={{width: "100%", maxWidth: "320px"}}
                    value={curSelected}
                    placeholder={`Select ${name}${multiple ? "s" : ""}`}
                    onFocus={() => setFocus(true)}
                    onBlur={() => setFocus(false)}
                    onChange={handleOnChangeWrapper}
                    disabled={readOnly}
                >
                {columns.map(c =>
                    <Select.Option key={c} value={c}>{c}</Select.Option>,
                )}
                </Select>
            </Col>
        </WideRow>
    )
}

export default ChartColumnSelectorRow
