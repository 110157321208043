
import { Collapse, Select } from "antd"
import React, { useState } from "react"
import { container } from "tsyringe"
import { ChartDataContainer } from "../../../../../../containers/ChartDataContainer"
import { OptionsCollapsePanel } from "../../../../../../style"
import { ChartKey } from "../../../../../../types"
import { buildOption as b } from "../../utils"
import Axis from "./Axis"
import { DatasetSelect } from "./style"

interface BarDatasetsProps {
    chartKey: ChartKey
}

const BarDatasets: React.FunctionComponent<BarDatasetsProps> = (props) => {
    const { chartKey } = props
    const header = "Datasets"
    const prefix = header.toLowerCase()

    const [selected, setSelected] = useState(0)

    const chartContainer = container.resolve(ChartDataContainer)
    const chartData = chartContainer.getData(chartKey)
    const columns = chartData?.dataColumns || []
    const types = ["line", "bar", "bubble", "scatter"]

    return(
        <Collapse bordered={false} style={{width: "100%"}} key={`${chartKey}${prefix}`}>
            <OptionsCollapsePanel header={header} key={"header"}>
                <DatasetSelect onSelect={v => typeof v === "number" && setSelected(v)} value={selected}>
                    {columns.map((c, idx) =>
                        <Select.Option value={idx} key={c.key}>
                            {c.name}
                        </Select.Option>,
                    )}
                </DatasetSelect>
                <Axis chartKey={chartKey} selected={selected}/>
                {b(chartKey, "Series Type", `chartjs.chartDataSets[${selected}].type`, "enum", types)}
                {b(chartKey, "Border Color", `chartjs.chartDataSets[${selected}].borderColor`, "color")}
                {b(chartKey, "Background Color", `chartjs.chartDataSets[${selected}].backgroundColor`, "color")}
                {b(chartKey, "Pt Border Color", `chartjs.chartDataSets[${selected}].pointBorderColor`, "color")}
                {b(chartKey, "Pt Background Color", `chartjs.chartDataSets[${selected}].pointBackgroundColor`, "color")}
                {b(chartKey, "Border Width", `chartjs.chartDataSets[${selected}].borderWidth`, "number")}
            </OptionsCollapsePanel>
        </Collapse>
    )
}

export default BarDatasets
