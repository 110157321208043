import { Input, Modal, Typography } from "antd"
import React, { useState } from "react"
import { AppConfigs } from "../../config"

const { Text, Paragraph } = Typography

interface SaveModalProps {
    onOk: (group: string, chartId: string) => void
    onCancel: () => void
    group: string
    chartId: string
}

const SaveModal: React.FunctionComponent<SaveModalProps> = (props) => {
    const {onOk, onCancel, group, chartId} = props
    const [newChartId, setNewChartId] = useState(chartId)
    const [saveGroup, setSaveGroup] = useState(group)

    const validChartId = (id: string) => /^[-_a-zA-Z0-9]{6,128}$/.test(id)

    // TODO: Change explore path below to users path or store to private location!

    return(
        <Modal
            title="Save Chart"
            visible={true}
            okType="primary"
            okText="Save"
            onOk={() => onOk(saveGroup, newChartId)}
            okButtonProps={{disabled: !validChartId(newChartId)}}
            onCancel={onCancel}
        >
            <Paragraph>Saving will create a <Text strong>public link</Text> containing your chart and data<br/>
            Anyone with this link will be able to view, but not edit your data<br/><br/>
            Type in the name you want to use for your chart</Paragraph>
            <Input onChange={t => setSaveGroup(t.target.value)} value={saveGroup}/>
            <Input onChange={t => setNewChartId(t.target.value)} value={newChartId}/>
            <Paragraph>
                <br/>
                Chart name must have:
                <ul>
                    <li>6 to 128 characters</li>
                    <li>Contain only contain letters, numbers, dashes (-) or underscores(_)</li>
                </ul>
            </Paragraph>

            <Paragraph>Url: </Paragraph>
            { validChartId(chartId) && <Paragraph copyable>
                {`${AppConfigs.basePath}${AppConfigs.chartsPath}/${saveGroup}/${newChartId}`}
                </Paragraph>
            }
            <Paragraph>Are you sure you want to continue?</Paragraph>
        </Modal>
    )
}

export default SaveModal
