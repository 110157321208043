import { Typography } from "antd"
import { TooltipPlacement } from "antd/lib/tooltip"
import React from "react"
import { container } from "tsyringe"
import TutorialCompleteModal from "../components/modals/TutorialCompleteModal"
import { TutorialContainer } from "../containers/TutorialContainer"
import { TutorialButton, TutorialDiv } from "./style"

interface Tutorial {
    content: JSX.Element,
    step: number,
    placement: TooltipPlacement
}

const tc = container.resolve(TutorialContainer)
const end = <TutorialButton onClick={tc.clear} size="small">End Tutorial</TutorialButton>

export const tutorial1: Tutorial = {
    content: <TutorialDiv>
        <Typography.Paragraph mark>Click here to add data</Typography.Paragraph>
        {end}
    </TutorialDiv>,
    step: 1,
    placement: "left",
}

const tutorial2Text = `x	x^2	x^3
0	0	0
0.5	0.25	0.125
1	1	1
1.5	2.25	3.375
2	4	8
2.5	6.25	15.625
3	9	27
3.5	12.25	42.875`

export const tutorial2: Tutorial = {
    content: <TutorialDiv>
        <Typography.Paragraph mark>
            Paste data here from Excel,
            csv or type it in manually.
        </Typography.Paragraph>
        <Typography.Paragraph>
            Try this data!
        </Typography.Paragraph>
        <Typography.Paragraph copyable={{text: tutorial2Text}}>
            <textarea readOnly style={{width: "100%"}} value={tutorial2Text}/>
        </Typography.Paragraph>
        {end}
    </TutorialDiv>
    ,
    step: 2,
    placement: "right",
}

export const tutorial3: Tutorial = {
    content: <TutorialDiv>
        <Typography.Paragraph mark>Click here to add a chart</Typography.Paragraph>
        {end}
    </TutorialDiv>,
    step: 3,
    placement: "left",
}

export const tutorial4: Tutorial = {
    content: <TutorialDiv>
        <Typography.Paragraph mark>Select the data you just added</Typography.Paragraph>
        {end}
    </TutorialDiv>,
    step: 4,
    placement: "right",
}

export const tutorial5: Tutorial = {
    content: <TutorialDiv>
        <Typography.Paragraph mark>Select chart type</Typography.Paragraph>
        <Typography.Paragraph>Try a line chart!</Typography.Paragraph>
        {end}
    </TutorialDiv>,
    step: 5,
    placement: "right",
}

export const tutorial6: Tutorial = {
    content: <TutorialDiv>
        <Typography.Paragraph mark>Select a label</Typography.Paragraph>
        <Typography.Paragraph>Try x!</Typography.Paragraph>
        {end}
    </TutorialDiv>,
    step: 6,
    placement: "right",
}

export const tutorial7: Tutorial = {
    content: <TutorialDiv>
        <Typography.Paragraph mark>Select one or more columns</Typography.Paragraph>
        <Typography.Paragraph>Try x^2 and x^3!</Typography.Paragraph>
        {end}
    </TutorialDiv>,
    step: 7,
    placement: "right",
}

export const tutorial8: Tutorial = {
    content: <TutorialDiv>
        <Typography.Paragraph mark>Check which options you can select from</Typography.Paragraph>
        {end}
    </TutorialDiv>,
    step: 8,
    placement: "bottom",
}

export const tutorial9: Tutorial = {
    content: <TutorialDiv>
        <Typography.Paragraph mark>When you're done, close it</Typography.Paragraph>
        {end}
    </TutorialDiv>,
    step: 9,
    placement: "left",
}

export const tutorial10: Tutorial = {
    content: <TutorialDiv>
        <Typography.Paragraph mark>Save your creation</Typography.Paragraph>
        {end}
    </TutorialDiv>,
    step: 10,
    placement: "bottom",
}

export const tutorial11: Tutorial = {
    content: <TutorialDiv>
        <Typography.Paragraph mark>Or create a personal link</Typography.Paragraph>
        {end}
    </TutorialDiv>,
    step: 11,
    placement: "left",
}

export const tutorialFinal = {
    content: <TutorialCompleteModal/>,
    step: 12,
}
