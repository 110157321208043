import { container } from "tsyringe"
import { ChartDataContainer } from "./ChartDataContainer"
import { ChartOptionsContainer } from "./ChartOptionsContainer"
import { DataContainer } from "./DataContainer"
import { MetaContainer } from "./MetaContainer"
import { TutorialContainer } from "./TutorialContainer"

const chartOptionsContainer = container.resolve(ChartOptionsContainer)
const dataContainer = container.resolve(DataContainer)
const chartDataContainer = container.resolve(ChartDataContainer)
const metaContainer = container.resolve(MetaContainer)
const tutorialContainer = container.resolve(TutorialContainer)

export const containerInstances = [
    dataContainer,
    chartDataContainer,
    chartOptionsContainer,
    metaContainer,
    tutorialContainer,
]
