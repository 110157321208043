import { Collapse, Select } from "antd"
import React, { useEffect, useState } from "react"
import { container } from "tsyringe"
import { ChartDataContainer } from "../../../../../../containers/ChartDataContainer"
import { OptionsCollapsePanel } from "../../../../../../style"
import { ChartKey, ChartDataProps } from "../../../../../../types"
import { DatasetSelect } from "../../chartjs/components/style"
import SwitchOption from "../../chartjs/components/SwitchOption"
import { buildOption, ChartPointShape } from "../../utils"
import TrendLines from "./TrendLines"

interface SeriesProps {
    chartKey: ChartKey
    includeTrendlines: boolean
}

const Series: React.FunctionComponent<SeriesProps> = (props) => {
    const { chartKey, includeTrendlines } = props
    const chartContainer = container.resolve(ChartDataContainer)
    const [data, setData] = useState<Readonly<ChartDataProps>>()
    const [selected, setSelected] = useState(0)
    const [isRAxis, setIsRAxis] = useState(false)
    const [rAxisIdx, setRAxisIdx] = useState(0)

    useEffect(() => {
        const d = chartContainer.getData(chartKey)
        const isR = d?.options.google.series && d?.options.google.series[selected]?.targetAxisIndex === 1
        setData(d)
        setIsRAxis(isR)
        setRAxisIdx(isR ? 1 : 0)
    }, [selected])

    useEffect(() => {
        const idx = data?.options.google.series[0]?.targetAxisIndex
        if (idx !== undefined) {
            setIsRAxis(idx === 1)
        }
    }, [])

    if (!data) { return null}
    // const out = flatMap(data.dataColumns, ((d, idx) => getSinglegoogle.series(chartKey, idx)))

    const toggle = (checked: boolean) => {
        const d = chartContainer.getData(chartKey)
        if (d !== undefined) {
            if (checked) {
                // move from left to right
                chartContainer.updateOption(chartKey, `google.series[${selected}].targetAxisIndex`, 1)
            } else {
                // move from right to left
                chartContainer.updateOption(chartKey, `google.series[${selected}].targetAxisIndex`, 0)
            }
            setIsRAxis(checked)
            setRAxisIdx(checked ? 1 : 0)
        }
    }

    const out = (
        <Collapse bordered={false} style={{width: "100%"}}>
            <OptionsCollapsePanel header="Series" key={"Series"}>
                <DatasetSelect onSelect={v => typeof v === "number" && setSelected(v)} value={selected}>
                    {data.dataColumns.map((c, idx) =>
                        <Select.Option value={idx} key={c.key}>
                            {c.name}
                        </Select.Option>,
                    )}
                </DatasetSelect>
                    { includeTrendlines &&
                        <TrendLines chartKey={chartKey} idx={selected}/>
                    }
                    {/* <Subscribe to={[ChartDataContainer]}>{(cdc: ChartDataContainer) => {
                        // depends on another options
                        const updatedData = cdc.getData(chartKey)
                        const options = [{label: "Left", }]
                        return buildOption(
                            chartKey, "Axis",
                            `google.series.${selected}.targetAxisIndex`,
                            "enum",
                            options,
                        )}
                    } */}
                    {/* </Subscribe> */}

                    <SwitchOption label="Axis" leftLabel="L" rightLabel="R" selected={isRAxis} onToggle={toggle} />
                    {buildOption(chartKey, "Color", `google.series.${selected}.color`, "color")}
                    {buildOption(chartKey, "Axis Label",
                        `google.vAxes[${rAxisIdx}].title`, "string", undefined)}
                    {buildOption(chartKey, "Label",
                        `google.series.${selected}.labelInLegend`, "string", undefined)}
                    {buildOption(chartKey, "Line Width",
                        `google.series.${selected}.lineWidth`, "number")}
                    {buildOption(chartKey, "Point Shape",
                        `google.series.${selected}.pointShape`, "enum", ChartPointShape)}
                    {buildOption(chartKey, "Point Size",
                        `google.series.${selected}.pointSize`, "number")}
                </OptionsCollapsePanel>
            </Collapse>
    )
    return out
}

export default Series
