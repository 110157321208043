import { Col, Select, Popover } from "antd"
import React from "react"
import { Subscribe } from "unstated"
import { ChartDataContainer } from "../../../containers/ChartDataContainer"
import { DataContainer } from "../../../containers/DataContainer"
import { WideRow } from "../../../style"
import { ChartKey, DataKey } from "../../../types"
import { TutorialContainer } from "../../../containers/TutorialContainer"
import { tutorial4 } from "../../../tutorial/tutorialSteps"

interface ChartSelectorProps {
    chartKey: ChartKey
    readOnly: boolean
}

const ChartDataSelector: React.FunctionComponent<ChartSelectorProps> = (props) => {
    const { chartKey, readOnly } = props

    return(

        <Subscribe to={[DataContainer, ChartDataContainer, TutorialContainer]}>
            {(dataContainer: DataContainer, chartDataContainer: ChartDataContainer, t: TutorialContainer) => {
                const chart = chartDataContainer.getData(chartKey)

                if (chart === undefined) { return null }
                const availableData = dataContainer.getDatas()
                return (
                    <Popover
                        content={tutorial4.content}
                        visible={t.state.curStep === tutorial4.step}
                        placement={tutorial4.placement}
                    >
                        <WideRow>
                            <Col span={8}>Data</Col>
                            <Col span={16}>
                                <Select
                                    defaultActiveFirstOption={true}
                                    style={{width: "100%", maxWidth: "320px"}}
                                    onChange={(d: DataKey) => {
                                        t.complete(tutorial4.step)
                                        chartDataContainer.updateDataKey(chartKey, d)}
                                    }
                                    placeholder={"Select Data"}
                                    value={chart.dataKey}
                                    disabled={readOnly}
                                >
                                    {availableData.map(d =>
                                        <Select.Option key={d.key}>
                                            {d.nameWithType}
                                        </Select.Option>)}
                                </Select>
                            </Col>
                        </WideRow>
                    </Popover>
                )
            }
        }
        </Subscribe>
)}

export default ChartDataSelector
