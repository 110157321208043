import { Card, Col, Divider, Row } from "antd"
import React from "react"

interface LandingFeaturesRowProps {
    title: string
    text: JSX.Element | string
    rightSide: JSX.Element
}

const sm = {span: 22, offset: 1}
const LandingFeaturesRow: React.FunctionComponent<LandingFeaturesRowProps> = (props) => {
    const { title, text, rightSide } = props
    return(
        <React.Fragment>
            <Row justify="center" style={{display: "flex", alignItems: "center"}}>
                <Col xs={sm} sm={sm} md={{span: 8, offset: 2}} lg={{span: 8, offset: 2}} xl={{span: 8, offset: 2}}>
                    <Card title={title}>
                        {text}
                    </Card>
                </Col>
                <Col style={{width: "fit-content", maxWidth: "50%"}}
                    xs={0} sm={0} md={{span: 11, offset: 1}} lg={{span: 11, offset: 1}} xl={{span: 10, offset: 2}}>
                    {rightSide}
                </Col>
            </Row>
            {/* TODO: this should be centered when in xs-md. could use
                style={{alignItems: "center", display: "flex", flexDirection: "column"}} 
                but keeps showing up when fully expanded.
            */}
            <Row justify="center" style={{display: "flex", alignItems: "center"}}>
                <Col style={{width: "100%", maxWidth: "90%" }}
                    xs={{span: 23, offset: 1}} sm={{span: 22, offset: 2}} md={0} lg={0} xl={0}>
                    <Divider/>
                    {rightSide}
                </Col>
            </Row>
            <Divider/>
        </React.Fragment>
    )
}

export default LandingFeaturesRow
