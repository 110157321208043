import { Col, Select } from "antd"
import React from "react"
import { WideRow } from "../../../style"
import { DelimiterType } from "../../../types"

interface DataDelimitorSelectorProps {
    disabled: boolean
    delimiterType?: DelimiterType
    handleChangeDelimiterType: (d: DelimiterType) => void
}

const DataDelimitorSelector: React.FunctionComponent<DataDelimitorSelectorProps> = (props) => {
    const {delimiterType, handleChangeDelimiterType, disabled} = props
    return(
        <WideRow>
            <Col span={8}>Delimiter</Col>
            <Col span={16}>
                <Select disabled={disabled} value={delimiterType} onChange={handleChangeDelimiterType} style={{width: "100%"}}>
                    <Select.Option value={DelimiterType.comma}>{DelimiterType.comma}</Select.Option>
                    <Select.Option value={DelimiterType.tab}>{DelimiterType.tab}</Select.Option>
                    <Select.Option value={DelimiterType.pipe}>{DelimiterType.pipe}</Select.Option>
                </Select>
            </Col>
        </WideRow>
    )
}

export default DataDelimitorSelector
