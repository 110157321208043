import React from "react"
import { buildOption as b } from "../../utils"

interface LineDatasetsInner {
    chartKey: string,
    selected: number
}

const LineDatasetsInner: React.FunctionComponent<LineDatasetsInner> = ({chartKey, selected}) => {

    const types = ["line", "bar", "bubble", "scatter"]

    const borderDashes = [
        { label: "solid", value: [] },
        { label: "6-6", value: [6, 6] },
        { label: "12-12", value: [12, 12] },
        { label: "5-1", value: [5, 1] },
        { label: "15-5", value: [15, 5] },
        { label: "3-5-8-5", value: [3, 5, 8, 5] },
    ]
    return(
        <React.Fragment>
            {b(chartKey, "Border Color", `chartjs.chartDataSets[${selected}].borderColor`, "color")}
            {b(chartKey, "Background Color", `chartjs.chartDataSets[${selected}].backgroundColor`, "color")}
            {b(chartKey, "Pt Border Color", `chartjs.chartDataSets[${selected}].pointBorderColor`, "color")}
            {b(chartKey, "Pt Background Color", `chartjs.chartDataSets[${selected}].pointBackgroundColor`, "color")}
            {b(
                chartKey,
                "Border Dash",
                `chartjs.chartDataSets[${selected}].borderDash`,
                "enum",
                borderDashes,
            )}
            {b(chartKey, "Border Width", `chartjs.chartDataSets[${selected}].borderWidth`, "number", undefined)}
            {b(
                chartKey,
                "Cubic Interol. Mode",
                `chartjs.chartDataSets[${selected}].cubicInterpolationMode`,
                "enum",
                ["default", "monotone"],
            )}
            {b(chartKey, "Label", `chartjs.chartDataSets[${selected}].label`, "string")}
            {b(chartKey, "Series Type", `chartjs.chartDataSets[${selected}].type`, "enum",  types)}
        </React.Fragment>
    )
}

export default LineDatasetsInner
