import { Collapse } from "antd"
import React from "react"
import { Subscribe } from "unstated"
import { ChartDataContainer } from "../../containers/ChartDataContainer"
import { DataContainer } from "../../containers/DataContainer"
import { MetaContainer } from "../../containers/MetaContainer"
import ChartPanel from "./chart/ChartPanel"
import DataPanel from "./data/DataPanel"

const CollapseManager: React.FunctionComponent = () => {

    return(
        <Subscribe to={[DataContainer, ChartDataContainer, MetaContainer]}>
            {(dataContainer: DataContainer, chartDataContainer: ChartDataContainer, metaContainer: MetaContainer) => {

                const datas = dataContainer.getDatas()
                const charts = chartDataContainer.getDatas()

                return (
                    <Collapse
                        accordion
                        activeKey={metaContainer.state.lastSelected}
                        onChange={k => !(k instanceof Array) && metaContainer.updateLastSelected(k)}
                    >
                        { datas.map(d => <DataPanel key={d.key} dataKey={d.key}/> )}
                        { charts.map(c => <ChartPanel key={c.key} chartKey={c.key}/> )}
                    </Collapse>
                )}}
        </Subscribe>

    )
}

export default CollapseManager
