import { Modal, Typography } from "antd"
import React from "react"
import { container } from "tsyringe"
import { AppConfigs } from "../../config"
import { TutorialContainer } from "../../containers/TutorialContainer"

const { Paragraph } = Typography

const TutorialCompleteModal: React.FunctionComponent = () => {
    const t = container.resolve(TutorialContainer)

    return(
        <Modal
            title="Tutorial Complete"
            visible={true}
            okType="primary"
            okText="Complete"
            onOk={t.clear}
            closable={false}
            cancelButtonProps={{ style: { display: "none" } }}
        >
            <Paragraph>Hopefully you enjoyed learning about some of the features Chart It has</Paragraph>
            <Paragraph>
                There's a lot more that we didn't go over but the best way to discover is to just click around
            </Paragraph>
            <Paragraph>
                And if you have any other questions feel free to reach out to <a href={`mailto:${AppConfigs.supportContact}`}>
                    {AppConfigs.supportContact}</a>
            </Paragraph>
        </Modal>
    )
}

export default TutorialCompleteModal
